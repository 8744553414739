import React, { useState } from 'react';
// import { useLocation } from "react-router-dom";
// Styles
import {
  TestW,
  Wrapper,
  Content,
  // Menu,
  // Item,
  // ItemLink,
} from "./MenuCircular.styles";

import {Link} from 'react-router-dom';
import ShowForPermission from '../../../ShowForPermission';




const MenuCircular = ({ items,idsend, categoryId }) => {
  const [isShown, setIsShown] = useState(false);
  
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const siteId = queryParams.get('siteId');
  const root = false;
 
  try {
    items=items[0];
  } catch (error) {
    items=null;
  }
  if(!items){
    return root ? (
      <TestW />
    ) : (
      <TestW>
        <Wrapper onMouseEnter={() => setIsShown(true)}
                     onMouseLeave={() => setIsShown(false)}>
          <Content>
        
                360
       
          </Content>
        </Wrapper>
      </TestW>
    );
  }else{
    return root ? (
      <TestW />
    ) : (
      <TestW>
        <Wrapper onMouseEnter={() => setIsShown(true)}
                     onMouseLeave={() => setIsShown(false)}>
          <Content>
         
                360
        
            <ul className="menu">
              {items && items.map((item, apiConfigId) => {
                return (
                  // <ShowForPermission permission={item.permissions}>
                  <ShowForPermission permission={item["transform_permission"]}>
                    
                         <li  className="spread" key={item+'-'+apiConfigId}>
                         {/* <a className="unit" href={item.href+'/?id='+String(idsend)+'&categoryId='+String(categoryId)}>
                           {item.nameMenu}
                         </a> */}
                         {}
                         <Link  className="unit" to={{      
                           pathname: item["v_menus_b360.href"]+'/?id='+String(idsend)+'&categoryId='+String(categoryId)+"&token="+token+"&siteId="+siteId, 
                           state: {item}
                         }}
                           ><div className="textoMenu">{item['v_menus_b360.nameMenu']}</div></Link>
                       </li>
                     
                  
                  </ShowForPermission>
                );
              })}
            </ul>
          </Content>
        </Wrapper>
      </TestW>
    );
  }

};

export default MenuCircular;

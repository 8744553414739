import React from 'react';

import { Wrapper, Content, Cabezera } from './Header.styles';
import * as config from '../../config';

// Hooks
import {useUrlFetchPost, useUrlFetchPostOrdered} from '../../hooks/useUrlFetch';
import React360Logo from '../../assets/images/React360Logo.png';

import MenuCircular from './Opciones';
const Header = ({header}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    const categoryId = queryParams.get('categoryId');
    const level3 = useUrlFetchPostOrdered(config.URL_LEVEL3,{"id":config['APICONFIGLVL3_'+String(categoryId)],"params":{"requestCategoryId":String(categoryId)}},"index", 1, "LEVEL3/"+id+categoryId);   
    return (
        <div className='menu-content'>
            <Content>
               <Cabezera> 
                        <img src={React360Logo} alt="logo"/>
               </Cabezera>
               
                <MenuCircular  items = {level3.state} idsend={id} categoryId={categoryId}/>
            </Content>
            {/* <RefreshButton /> */}
        </div>
    )
};

export default Header;
import styled from "styled-components";

export const Wrapper = styled.div`
   
   
    h1{
        margin: 20px auto;
        width: fit-content;
        color: #000000;
    }
`;

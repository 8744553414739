import styled from 'styled-components';

export const ImgContent = styled.div`
        display: flex;
        align-items:center;
        justify-content: center;
       img{
            width: 180px;
            height: 140px;
       }
`;

export const Wrapper = styled.div`
    display: flex;
    align-items:center;
    justify-content: center;
    margin: auto;
    width: 100%;
   
    height: 100%;
    position: absolute
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    
    -webkit-animation: 15s colorgroup infinite;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center right;
    width:100%;
    height:100%;
    h1{
        color: #fff;
    }

    img{
       
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 0.0px );
        -webkit-backdrop-filter: blur( 0.0px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
    }
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LoadingDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 20px;
    justify-self: center;
    align-self: center;
    h3{
        color: white;
    }
`;

export const FloatingButton = styled.div`
    position: absolute;
    right: 10px;
    top: 60px;
`;
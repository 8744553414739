import styled from 'styled-components';

export const Wrapper = styled.div`
    background: rgba( 104, 125, 135, 0.35 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 2.0px );
    -webkit-backdrop-filter: blur( 2.0px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    /* background: rgba( 22, 20, 20, 0.55 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 ); */


    /* max-width: var(--maxWidth); */
    margin: 0 auto;
    padding: 0 20px;
    
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(${({length})=>length}, fit-content(200px));
    margin: 5px 0 10px 0;
    /* grid-gap: 1rem;
    row-gap: 10px; */

`;

export const HeaderItem = styled.div`
    padding: 20px 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#2d68c4;
    border-radius: 10px;
    margin: 5px 2px 5px 2px;
    color: var(--white);
    ${({styles})=>styles}
`;

export const RowItem = styled.div`
    text-align: center;
    padding: 20px 10px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom: 1px solid white;
    color: #2d68c4;
    


    ${({info})=>(info ==='Procesada' ? 'color: green;':'')}
    ${({styles})=>styles}
`;

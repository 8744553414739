import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 80%;
    height: 80px;
    margin: auto; 
    padding: 20px 40px;
    border-radius: 4px;
    box-sizing: border-box;
    background: #fff;
`;

export const Content = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    height: 10px;
    background: #f1f1f1;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
    border-radius: 4px;
    overflow: hidden;

    :after {
        content: '';
        position: absolute;
        left: 0;
        width: 0;
        height: 100%;
        border-radius: 4px;
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);
        ${({loadTime})=>`animation: load ${loadTime/1000}s infinite;`}
        
    }

    @keyframes load {
    0% {
      width: 0;
      background: #a28089;
    }
    
    25% {
      width: 40%;
      background: #a0d2eb;
    }
    
    50% {
      width: 60%;
      background: #ffa8b6;
    }
    
    75% {
      width: 75%;
      background: #d0bdf4;
    }
    
    100% {
      width: 100%;
      background: #494d5f;
    }
  }
`;
  

  
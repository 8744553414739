import { object } from '@amcharts/amcharts4/core';
import React,{useState, useEffect} from 'react';

// Styles
import {Wrapper, Content, HeaderItem, RowItem} from './Table.styles';

import Modal from './components/modal';
 const useModal = () => {
    const [isVisible, setIsVisible] = useState(false);
  
    function toggleModal(data) {
      setIsVisible(!isVisible);
    }
  return {
      isVisible,
      toggleModal,
    }
  };
const TablaAction = ({columns, data}) => {

    const {isVisible, toggleModal} = useModal();
    const [columnsData, setColumnsData] = useState(columns);
    const [rowData, setRowData] = useState(data);
    const [rowSelected, setRowSelet] = useState(null);
    useEffect(() => {
        setColumnsData(columns);
        setRowData(data);
    },[columns,data])
    const rowSelect = (value) => {
        setRowSelet(value);
        toggleModal(value);
    }
    return(
        <Wrapper>
            <Modal isVisible={isVisible} rowSelected={rowSelected} hideModal={toggleModal}></Modal>
            {columnsData.length && 
            <Content length={columnsData.length}>
                {columnsData.map(item=>(
                    <HeaderItem key={item.title} styles={item.style}>{item.title}</HeaderItem>
                ))}
                
                {rowData.map(row=>{
                    let i =0;
                    return(
                        columnsData.map(item=>(
                            <RowItem  onClick={()=>rowSelect(row)} key={i} info={row[item.field]} number={i++} styles={item.style}>{row[item.field]}</RowItem>
                        ))
                    );
                }
                )}
            </Content>
            }
        </Wrapper>
    );
};

export default TablaAction;

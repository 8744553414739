import React from 'react';
import { Wrapper, Content } from './TablaGeneral.styles';
import Tabla from './Table';
import Loader from '../Loader';
import {useUrlFetchPost} from '../../hooks/useUrlFetch';


const TablaGeneralWithAction = (props) => {

    var apiUrl, sessionVariable, datos, data_send,params;
    try{

        const queryParams = new URLSearchParams(window.location.search);
        //Juliano aca
        const siteid = queryParams.get('siteId'); //'7200220034P-HSA-CFE-COSOLTEPEC'
        datos = props.location.state.item;
        apiUrl = datos["v_menus_b360.sourcedata"];
        params = datos["v_menus_b360.params"].replace("requestSiteId",siteid);
        data_send = {
        "id":datos["v_menus_b360.apiConfigId"],
        "params":JSON.parse(params)
        }
    }catch(error){
        datos = null;
        apiUrl = null;
        sessionVariable=null;
    }
    
    // reading from the api
    const apiData = useUrlFetchPost(apiUrl, data_send);
    console.log(apiData);
    // getting the json to visualize in the table
    const tableData = apiData.state[0];
  
    return (
        <Wrapper>
            {apiData.loading && 
            <Content>
            {/* <h3 style={{color: "#2d68c4"}}>Loading</h3> */}
            <Loader/>
            </Content>
            }
            {tableData && datos && 
            <>
            {/* <h1>{datos["v_menus_b360.nameMenu"]}</h1> */}
            <br/>
            <br/>
            <Tabla
                props={props}
                columns={tableData.columns}
                data={tableData.rows}
            />
            </>}
        </Wrapper>
    );
};

export default TablaGeneralWithAction;
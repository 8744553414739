import { useState, useEffect } from "react";

// API
import API from '../API';

// Session
import {isPersistedState} from '../session';

const useUrlFetch = (url) => {

    // States
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {

        const fetchUrl = async () => {
            try{
                setLoading(true);
                setError(false);

                // Fetching from API
                const data = await API.fetchFromUrl(url);
                setState(()=>(data.response));
                // console.log("Fetching from ",url, "\nData: ", data.response);
                
            }catch(error){
                console.log("Fetching error from API: ", error);
                setError(true);
            }
            setLoading(false);
        }

        const sessionState = isPersistedState(url);
        if(sessionState){
            // console.log("Fetching from session");
            setState(sessionState);
            setLoading(false);
            return;
        }
        fetchUrl();
    },[url]);

    // Write to the session state
    useEffect(() =>{
        if(!loading) sessionStorage.setItem(url, JSON.stringify(state));
    },[url, state, loading]);

    return {state, loading, error};
}


 const useUrlFetchPost = (url,data_send, sessionVariable) => {

    // States
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    // console.log("CALLING API POSTTTTT")
    useEffect(() => {

        const fetchUrl = async () => {
            try{
                setLoading(true);
                setError(false);
                const data = await API.postToUrl(url,data_send);
                setState(()=>(data.response));
                // console.log("Fetching from ",url, "\nData: ", data.response);
                
            }catch(error){
                console.log("Fetching error from API: ", error);
                setError(true);
            }
            setLoading(false);
        }

        const sessionState = isPersistedState(sessionVariable);
        if(sessionState){
            // console.log("Fetching from session");
            setState(sessionState);
            setLoading(false);
            return;
        }
        if(url===null || sessionVariable=== null)
            return;
        
        fetchUrl();
    },[url]);

    // Write to the session state
    useEffect(() =>{
        if(!loading && sessionVariable!=="dont save") sessionStorage.setItem(sessionVariable, JSON.stringify(state));
    },[url, state, loading]);

    return {state, loading, error};
}

const useUrlFetchPostOrdered = (url, data_send, field, order, sessionVariable) => {
    /**
     * url: the url to fetch the data
     * field: the name of the field to sort by
     * order: 1(asc) or -1(desc)
     */

    // States
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {

        const fetchUrl = async () => {
            try{
                setLoading(true);
                setError(false);

                // Fetching from API
                const data = await API.postToUrl(url,data_send);
                // console.log("Fetching from ",url, "\nData: ", data.response);

                const orderedData = [data.response[0].sort((a,b)=> (a[field]>b[field]?1:-1)*order)]
                console.log("Ordered",orderedData);
                setState(()=>(orderedData));
                
            }catch(error){
                // console.log("Fetching error from API: ", error);
                setError(true);
            }
            setLoading(false);
        }

        const sessionState = isPersistedState(sessionVariable);
        if(sessionState){
            // console.log("Fetching from session");
            setState(sessionState);
            setLoading(false);
            return;
        }
        if(url===null || sessionVariable=== null)
        return;
    
        fetchUrl();
    },[url, field, order]);

    // Write to the session state
    useEffect(() =>{
        if(!loading) sessionStorage.setItem(sessionVariable, JSON.stringify(state));
    },[url, state, loading]);

    return {state, loading, error};
}

export {useUrlFetch,useUrlFetchPost,useUrlFetchPostOrdered};
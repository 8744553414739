import React, { useEffect, useState } from "react";
import {
  Wrapper,
  Wrapper2,
  Wrapper3,
  Button,
  LoadingDiv,
  DataInput,
  TableContent,
  RowItem,
  HeaderItem,
} from "./nacimiento.styles";
import Tablax from "../Tabla2";
import ReactDOM from "react-dom";
import Loader from "../Loader";
import { useUrlFetchPost } from "../../hooks/useUrlFetch";
import swal from "sweetalert";
import ShowForPermission from "../../ShowForPermission";
import { setGlobalState} from "../../session";

const Nacimiento = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  // const categoryId = queryParams.get('categoryId');
  var apiUrl, sessionVariable, datos, data_send, params;
  try {
    datos = props.location.state.item;
    apiUrl = datos["v_menus_b360.sourcedata"];
    params = datos["v_menus_b360.params"].replace("requestId", id);
    data_send = {
      id: datos["v_menus_b360.apiConfigId"],
      params: JSON.parse(params),
    };
    sessionVariable = window.location.pathname + window.location.search;
  } catch (error) {
    datos = null;
    apiUrl = null;
    sessionVariable = null;
  }
  // reading from the api
  const apiData = useUrlFetchPost(apiUrl, data_send, sessionVariable);

  // const tableData = apiData.state[1];

  const [tableData, setTableData] = useState(null);
  
  const [vinicial, setVinicial] = useState(false);
  const [deleterow, setDeleteRow] = useState(false);
  
  useEffect(() => {
    try {
        setTableData(apiData.state[1]);
        if(tableData.rows.length>1){
          setVinicial(true);
          setDeleteRow(true);
        }
    } catch (error) {
      console.log(error);
    }
  }, [apiData]);

  const handlerow = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const apibutton = apiData.state[0].apiButtonPivot;
    const apiparams = apiData.state[0].params;

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(apiparams),
      redirect: "follow",
    };

    const ApiHandler = () => {
      const apiDataPost = useUrlFetchPost(apibutton, apiparams, "dont save");

      useEffect(() => {
        if (apiDataPost.loading) {
          console.log("CALLING API");
        }
        if (apiDataPost.error) {
          swal({
            title: "Api Error",
            icon: "error",
          });
        }
        if (!apiDataPost.loading && !apiDataPost.error) {
          if(deleterow){
            tableData.rows.splice(-1, 1)
          }
          const newRow = apiDataPost.state;
          const newTable = { ...tableData };
          newTable.rows.push({
            "mnos_bt_activation_data.id": 12581,
            "mnos_bt_activation_data.hubId": 1,
            "mnos_bt_activation_data.beamId": 1,
            "mnos_bt_activation_data.btId": 1964,
            "mnos_bt_activation_data.siteId": "270SPML1149",
            "mnos_bt_activation_data.azimuth":
              newRow[0]["mnos_bt_activation_data.azimuth"],
            "mnos_bt_activation_data.uplinkPolarization":
              newRow[1]["mnos_bt_activation_data.uplinkPolarization"],
            "mnos_bt_activation_data.elevation":
              newRow[7]["mnos_bt_activation_data.elevation"],
            "mnos_bt_activation_data.esn": "10463912",
            "mnos_bt_activation_data.systemaStateCode":
              newRow[2]["mnos_bt_activation_data.systemaStateCode"],
            "mnos_bt_activation_data.terminalStateCode":
              newRow[3]["mnos_bt_activation_data.terminalStateCode"],
            "mnos_bt_activation_data.latitude":
              newRow[4]["mnos_bt_activation_data.latitude"],
            "mnos_bt_activation_data.longitude":
              newRow[5]["mnos_bt_activation_data.longitude"],
            "mnos_bt_activation_data.downlinkModCod": "16apsk 5/6(21)",
            "mnos_bt_activation_data.ocuPowerType": 10,
            "mnos_bt_activation_data.genstLastRebootReason":
              "Power Cycle Reboot",
            "mnos_bt_activation_data.downlinkEsNo":
              newRow[8]["mnos_bt_activation_data.downlinkEsNo"],
            "mnos_bt_activation_data.uplinkEsNo":
              newRow[9]["mnos_bt_activation_data.uplinkEsNo"],
            "mnos_bt_activation_data.hardwareType":
              newRow[6]["mnos_bt_activation_data.hardwareType"],
            "mnos_bt_activation_data.uplinkCoderate": "9/10",
            "mnos_bt_activation_data.uplinkPowerBackOff": 20,
            "mnos_bt_activation_data.uplinkSymbolRate": 4096,
            "mnos_bt_activation_data.currentSwVersionId": "4.0.0.17",
            "mnos_bt_activation_data.servicePlanId": "609",
            "mnos_bt_activation_data.terminalStatus": null,
            "mnos_bt_activation_data.beamLocation": null,
            "mnos_bt_activation_data.suspensionState": null,
            "mnos_bt_activation_data.activationState": null,
            "mnos_bt_activation_data.ipv4Address": null,
            "mnos_bt_activation_data.ipv6Address": null,
            "mnos_bt_activation_data.availTokens": null,
            "mnos_bt_activation_data.checkDigit": null,
            "mnos_bt_activation_data.associationTime": null,
            "mnos_bt_activation_data.fapStatus": null,
            "mnos_bt_activation_data.sai": null,
            "mnos_bt_activation_data.swProfileId": null,
            "mnos_bt_activation_data.tiemStamp": null,
            "mnos_bt_activation_data.swapAllowed": null,
            "mnos_bt_activation_data.oduPower": null,
            "mnos_bt_activation_data.moveAllowed": null,
            "mnos_bt_activation_data.vsatsPiparams": null,
            "mnos_bt_activation_data.staticIpSubnetSize": null,
            "mnos_bt_activation_data.associatedIpgwName": null,
            "mnos_bt_activation_data.bytesRxSinceAssoc": null,
            "mnos_bt_activation_data.bytesTxSinceAssoc": null,
            "mnos_bt_activation_data.inPeakPeriod": null,
            "mnos_bt_activation_data.offPeakOverallCapacity": null,
            "mnos_bt_activation_data.offPeakOveralUsage": null,
            "mnos_bt_activation_data.overallCapacity": null,
            "mnos_bt_activation_data.overallUsage": null,
            "mnos_bt_activation_data.pepBbstatus": null,
            "mnos_bt_activation_data.staticIpv4Subnet": null,
            "mnos_bt_activation_data.staticIpv4SubnetType": null,
            "mnos_bt_activation_data.oracleId": null,
            "mnos_bt_activation_data.delay": "0",
            "mnos_bt_activation_data.currentOutrouteStreamID": "0",
          });
          setTableData(newTable);
          let updateSessionData = {...apiData.state};
          updateSessionData[1] = {...newTable};
          console.log(updateSessionData);
          setGlobalState(sessionVariable, updateSessionData);
          swal({
            title: "Tabla actualizada",
            icon: "success",
          });
          setVinicial(true)
          setDeleteRow(true)
        }
      }, [apiDataPost]);

      return <></>;
    };
    let wrapper = document.createElement("div");
    ReactDOM.render(<ApiHandler />, wrapper);

    swal({
      title: "Llamando la Api",
      text: "Loading",
      icon: "warning",
    });
  };

  return (
    <>
      <Wrapper>
        {!tableData && !apiData.loading && (
          <h1 className="errorHeader">NO DATA</h1>
        )}
        {apiData.loading && (
          <LoadingDiv>
            <h3>Loading</h3>
            <Loader />
          </LoadingDiv>
        )}
        {tableData && datos && (
          <>
            <h1>{datos["v_menus_b360.nameMenu"]}</h1>
            <ShowForPermission permission="nacimiento">
              <Wrapper3>
                <Button onClick={handlerow}>Obtener Valor Actual</Button>
              </Wrapper3>

              <Wrapper2>
                <HeaderItem>Partida de Nacimiento</HeaderItem>
                <HeaderItem>Valor Inicial</HeaderItem>
                {vinicial && <HeaderItem >Valor Actual</HeaderItem>}
                
                <div>
                  <Tablax columns={tableData.columns} data={tableData.rows} />
                </div>
              </Wrapper2>
            </ShowForPermission>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Nacimiento;

import React, { useState, useEffect } from "react";
import Tabla from "../Tabla";
import { useTableFetch } from "../../hooks/useTableFetch";
import { Wrapper } from "./pivot.styles";

import PivotGrid, { FieldChooser } from "devextreme-react/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

import { sales, usePrueba } from "./data.js";

function TablaMaster() {
  const [data, setData] = useState(null);
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    fetch("https://mocki.io/v1/054a792a-1cf1-4c8e-a65c-e96b2843f8d2")
      .then((response) => {
        return response.json();
      })
      .then((recurso) => {
        setData(
          recurso.results.find((item) => item.module === "pivot").dataResult.columns
        );
        const dataSource = new PivotGridDataSource({
          fields: [
            {
              caption: "Region",
              width: 120,
              dataField: "region",
              area: "row",
            },
            {
              caption: "City",
              dataField: "city",
              width: 150,
              area: "row",
              selector: function(data) {
                return `${data.city} (${data.country})`;
              },
            },
            {
              dataField: "date",
              dataType: "date",
              area: "column",
            },
            {
              caption: "Sales",
              dataField: "amount",
              dataType: "number",
              summaryType: "sum",
              format: "currency",
              area: "data",
            },
          ],
          store: data,
        });

        // const dataSource2 = new PivotGridDataSource({
        //   fields: [
        //     {
        //       caption: "Region",
        //       width: 120,
        //       dataField: "region",
        //       area: "row",
        //     },
        //     {
        //       caption: "City",
        //       dataField: "city",
        //       width: 150,
        //       area: "row",
        //       selector: function(data) {
        //         return `${data.city} (${data.country})`;
        //       },
        //     },
        //     {
        //       dataField: "date",
        //       dataType: "date",
        //       area: "column",
        //     },
        //     {
        //       caption: "Sales",
        //       dataField: "amount",
        //       dataType: "number",
        //       summaryType: "sum",
        //       format: "currency",
        //       area: "data",
        //     },
        //   ],
        //   store: sales,
        // });
    
        setDatas(dataSource);
        // console.log("entro ye",datas, dataSource, dataSource2)
      });

   
  }, []);

  return (
    <Wrapper>
      {data && (
        <React.Fragment>
          {console.log("entro", data.dataResult)}
          <div className="long-title">
            <h3>Pivot</h3>
          </div>
          <PivotGrid
            id="sales"
            dataSource={datas}
            allowSortingBySummary={true}
            allowSorting={true}
            allowFiltering={true}
            allowExpandAll={true}
            height={400}
            width={1000}
            showBorders={true}
          >
            <FieldChooser enabled={false} />
          </PivotGrid>
        </React.Fragment>
      )}
    </Wrapper>
  );
}

export default TablaMaster;

import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LoadingDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 20px;
    justify-self: center;
    h1{
        display: inline;
        color:#000000 !important;
    };
    align-self: center;
    h3{
        color: white;
    }
`;
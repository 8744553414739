import React from "react";
import {
    Wrapper,
    Wrapper2,
    Wrapper3,
    Button,
    LoadingDiv,
    HeaderItem,
} from "./calendary.styles";
import Scheduler from 'devextreme-react/scheduler';
import { data } from './data.js';

const currentDate = new Date(2021, 10, 17);
const views = ['month', 'week', 'day'];

const Calendary = () => {

    return (
        <>
            <Wrapper className="dx-viewport">
                <h1>Calendario</h1>
                <Scheduler
                    timeZone="America/Los_Angeles"
                    dataSource={data}
                    views={views}
                    defaultCurrentView="month"
                    defaultCurrentDate={currentDate}
                    height={600}
                    width={1000}
                    editing={false}
                    startDayHour={9} />
            </Wrapper>
        </>
    );
};

export default Calendary;

import React from 'react'
import Chart, {
    CommonSeriesSettings,
    Series,
    Pane,
    ValueAxis,
    Export,
    Legend,
    Label,
    Title,
    Grid,
} from 'devextreme-react/chart';
import { weatherData } from './data';

const Indicators = () => {
    return (
        <>
        
        <div>
            <h1>Indicadores</h1>
        </div>
        <Chart
            id="chart"
            dataSource={weatherData}
            defaultPane="bottomPane"
            title="Temperatura °C"
        >
            <CommonSeriesSettings argumentField="month" />
            <Series
                pane="topPane"
                color="#b0daff"
                type="rangeArea"
                rangeValue1Field="minT"
                rangeValue2Field="maxT"
                name="Monthly Temperature Ranges, °C"
            />
            <Series
                pane="topPane"
                valueField="avgT"
                name="Average Temperature, °C"
            >
                <Label
                    visible={true}
                    customizeText={temperatureCustomizeText}
                />
            </Series>

            <Pane name="topPane" />

            <ValueAxis pane="topPane">
                <Grid visible={true} />
                <Title text="Temperature, °C" />
            </ValueAxis>

            <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
            />
            <Export enabled={true} />
        </Chart>
        </>
    )
}
function temperatureCustomizeText({ valueText }) {
    return `${valueText} °C`;
  }

export default Indicators;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {isGlobalState} from './session';

const ShowForPermissionComponent = (props) => {
    const userPermissions = isGlobalState("permission");
    const options = [...userPermissions["permission_option"],"button","incidencias","mapa","dataconsumochart","dataconsumotimeseries","dataconsumotable","nacimiento","ordenes","graficoslivesnr","graficoslivemodcod"];
    // const options = [...userPermissions["permission_option"], "menu","button","incidencias","mapa","dataconsumo","nacimiento","ordenes","graficoslive"];
    const couldShow = options.includes(props.permission);
    return couldShow ? props.children : null;
};

export default ShowForPermissionComponent;
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --white: #fff;
        --lightGrey: #eee;
        --medGrey: #353535;
        --darkGrey: #1c1c1c;
        --lightBlue: #4fb5de;
        --medBlue: #3b9ece;
        --fontSuperBig: 2.5rem;
        --fontBig: 1.5rem;
        --fontMed: 1.2rem;
        --fontSmall: 1rem;
    }

    *{
        box-sizing: border-box;
        font: 'Abel', sans-serif;
    }

    html{
        min-height:100%;/* make sure it is at least as tall as the viewport */
        position:relative;
    }

    body{
        margin: 0;
        padding: 0; 
        height: -webkit-fill-available;
        position: fixed;

        h1{
            font-size: 2rem;
            font-weight: 600;
            color: var(--white);
        }

        h3{
            font-size: 1.1rem;
            font-weight: 600;
            color: var(--white);
        }

        p{
            font-size: 1rem;
            color: var(--white);
        }
    }

`;
import { Wrapper, Content, DataInput, LoadingDiv } from "./DataGeneral.styles";
import { useUrlFetchPost } from "../../hooks/useUrlFetch";
import Loader from "../Loader";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { red } from "@material-ui/core/colors";

const DataGeneral = (props) => {
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  // const categoryId = queryParams.get('categoryId');
  var apiUrl, sessionVariable, datos, data_send, params;
  try {
    datos = props.location.state.item;
    apiUrl = datos["v_menus_b360.sourcedata"];
    params = datos["v_menus_b360.params"].replace("requestId", id);
    console.log(apiUrl);
    data_send = {
      id: datos["v_menus_b360.apiConfigId"],
      params: JSON.parse(params),
    };

    sessionVariable = window.location.pathname + window.location.search;
  } catch (error) {
    datos = null;
    apiUrl = null;
    sessionVariable = null;
  }

  // reading from the api
  const apiData = useUrlFetchPost(apiUrl, data_send, sessionVariable);
  // data to show
  const [data, setData] = useState(apiData.state);
  console.log(data);
  useEffect(() => {
    const ApiHandler = () => {
      const apiDataPost = useUrlFetchPost(apiUrl, data_send, sessionVariable);

      useEffect(() => {
        if (apiDataPost.loading) {
          setLoading(true);
          setData(null);
        }
        if (!apiDataPost.loading) {
          setLoading(false);
          setData(apiDataPost.state)
        }
      }, [apiDataPost])
      return <>
      </>;
    }
    let wrapper = document.createElement('div');
    ReactDOM.render(<ApiHandler />, wrapper);
  }, [sessionVariable]);
  console.log(tableData, apiUrl, data_send, sessionVariable);

  const getCustomRules = function (item) {
    let customStyle = {}
    if (item.value === 'offline')
      customStyle = {color:'red'}
    // if(item.label === 'transform.title')
    //   customStyle = {display:'none'}
    return customStyle
  }

  /**
   * VISUAL CHANGE FOR ACTIVATION DATE - only show the date and not the time
   * e.g. 2020-01-01 00:00:00  turns into 2020-01-01 (withoput the time)
   */
  if (data && data.length > 0) {
    data.map(item => {
      if (item.label === "Fecha de Activacion") {
        item.value = item.value.split(" ")[0];
      }
    })
  }
  return (
    <Wrapper>
      {!data && !loading && <h2 className="errorHeader">NO DATA</h2>}
      {loading && (
        <LoadingDiv>
          {/* <h3>Loading</h3> */}
          <Loader />
        </LoadingDiv>
      )}

      {data && datos && data.length && (
        <>
          <h1>{datos["v_menus_b360.nameMenu"]}</h1>
          <Content>
            {datos &&
              <>
                {data.map((item) => item.label === 'transform.title' ? <></> : [
                  <DataInput key={`${item.label}field`}>
                    <label className="field-style">{item.label}</label>
                  </DataInput>,
                  <label key={`${item.label}:`} className="separator">
                    :
                  </label>,
                  <DataInput key={`${item.label}val`} styles={item.style} 
                    customStyle={getCustomRules(item)}>
                    <label className="value-style centered">{item.value}</label>
                  </DataInput>,
                ])}
              </> ||
              <>
                <DataInput >
                  <label className="field-style">Proximamente</label>
                </DataInput>
                <label className="separator">
                  :
                </label>
                <DataInput >
                  <label className="value-style centered">Proximamente</label>
                </DataInput>
                <DataInput >
                  <label className="field-style">Proximamente</label>
                </DataInput>
                <label className="separator">
                  :
                </label>
                <DataInput >
                  <label className="value-style centered">Proximamente</label>
                </DataInput>
              </>
            }


            {/* {data.map((item) => [
              <DataInput key={`${item.label}field`}>
                <label className="field-style">{item.label}</label>
              </DataInput>,
              <label key={`${item.label}:`} className="separator">
                :
              </label>,
              <DataInput key={`${item.label}val`} styles={item.style}>
                <label className="value-style centered">{item.value}</label>
              </DataInput>,
            ])} */}
          </Content>
        </>
      )}
    </Wrapper>
  );
};
function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
export default DataGeneral;

import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 0 5px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #01010191;
    backdrop-filter: blur( 2.0px );
    -webkit-backdrop-filter: blur( 2.0px );
    border-radius: 8px;
    /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */

    @media screen and (max-width: 1024px){
        display: none;
    }
`;

export const Content = styled.div`
    display: grid;
    /* grid-template-columns: 100px 1fr 450px; */
    grid-template-columns: 100%;
    flex-direction: column;
    grid-template-rows: fit-content(250px) fit-content(350px) 1fr;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin:  0;
`;
import React from 'react';
import { Wrapper, Content } from './TablaGeneral.styles';
import Tabla from '../TablaDevExtreme';
import Loader from '../Loader';
import {useUrlFetchPost} from '../../hooks/useUrlFetch';


const TablaGeneral = (props) => {

    var apiUrl, sessionVariable, datos, data_send,params;
    try{

        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');
        datos = props.location.state.item;
        apiUrl = datos["v_menus_b360.sourcedata"];
        params = datos["v_menus_b360.params"].replace("requestId",id);
        data_send = {
        "id":datos["v_menus_b360.apiConfigId"],
        "params":JSON.parse(params)
        }
        sessionVariable = datos["v_menus_b360.href"];
    }catch(error){
        datos = null;
        apiUrl = null;
        sessionVariable=null;
    }
    
    // reading from the api
    const apiData = useUrlFetchPost(apiUrl, data_send, sessionVariable);
    // getting the json to visualize in the table
    const tableData = apiData.state[0];
    console.log("HEHEHE", props)
    return (
        <Wrapper>
            {apiData.loading && 
            <Content>
            <h3>Loading</h3>
            <Loader/>
            </Content>
            }
            {tableData && datos && 
            <>
            <h1>{datos["v_menus_b360.nameMenu"]}</h1>
            <Tabla
                columns={tableData.columns}
                data={tableData.rows}
            />
            </>}
        </Wrapper>
    );
};

export default TablaGeneral;
export const multiViewItems = [{
    ID: 1,
    Subterraneo: 'SuprMart',
    title: 'Fundación',
    Material: '702 SW 8th Street',
    Númeroductos: 'Bentonville',
    Diámetroductos: 'Arkansas',
    Zipcode: 72716,
    longitudlineal: '(800) 555-2797',
    Proporcionadoscliente: '(800) 555-2171',
    Website: 'http://www.nowebsitesupermart.com',
  }, {
    ID: 2,
    Subterraneo: "El'Depot",
    title: 'Canalización',
    Material: '2455 Paces Ferry Road NW',
    Númeroductos: 'Atlanta',
    Diámetroductos: 'Georgia',
    Zipcode: 30339,
    longitudlineal: '(800) 595-3232',
    Proporcionadoscliente: '(800) 595-3231',
    Website: 'http://www.nowebsitedepot.com',
  }, {
    ID: 3,
    Subterraneo: 'K&S Music',
    title: 'Energía outdoor',
    Material: '1000 Nicllet Mall',
    Númeroductos: 'Minneapolis',
    Diámetroductos: 'Minnesota',
    Zipcode: 55403,
    longitudlineal: '(612) 304-6073',
    Proporcionadoscliente: '(612) 304-6074',
    Website: 'http://www.nowebsitemusic.com',
  }, {
    ID: 4,
    Subterraneo: 'Cable IFL',
    title: 'Cable IFL',
    Material: '999 Lake Drive',
    Númeroductos: 'Issaquah',
    Diámetroductos: 'Washington',
    Zipcode: 98027,
    longitudlineal: '(800) 955-2292',
    Proporcionadoscliente: '(800) 955-2293',
    Website: 'http://www.nowebsitetomsclub.com',
  }, {
    ID: 5,
    Subterraneo: 'Tom Club',
    title: 'Fibra Óptica',
    Material: '999 Lake Drive',
    Númeroductos: 'Issaquah',
    Diámetroductos: 'Washington',
    Zipcode: 98027,
    longitudlineal: '(800) 955-2292',
    Proporcionadoscliente: '(800) 955-2293',
    Website: 'http://www.nowebsitetomsclub.com',
  }, {
    ID: 6,
    Subterraneo: 'Tom Club',
    title: 'Cable Gestión/Control',
    Material: '999 Lake Drive',
    Númeroductos: 'Issaquah',
    Diámetroductos: 'Washington',
    Zipcode: 98027,
    longitudlineal: '(800) 955-2292',
    Proporcionadoscliente: '(800) 955-2293',
    Website: 'http://www.nowebsitetomsclub.com',
  }];
  
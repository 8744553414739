export const isPersistedState = stateName => {
    const sessionState = sessionStorage.getItem(stateName);
    return sessionState && JSON.parse(sessionState);
}

export const isGlobalState = stateName => {
    const globalState = sessionStorage.getItem(stateName);
    return globalState && JSON.parse(globalState);
}

export const setGlobalState = (stateName, state) => {
    sessionStorage.setItem(stateName, JSON.stringify(state));
}
import styled from 'styled-components';

export const Wrapper = styled.div`
    
`;

export const Content = styled.div`
    display: grid;
    /* grid-template-columns: 100px 1fr 450px; */
    grid-template-columns: 100%;
    flex-direction: column;
    grid-template-rows: fit-content(250px) fit-content(350px) 1fr;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin:  0;
`;

export const Cabezera = styled.div`
    display:flex;
    justify-content:center;
    width:100%;
    img{
        heigth:120px;
        width:160px;
    }
    margin-bottom:20px;
`;
import React,{useState, useEffect} from 'react';

// Styles
import {Wrapper, Content, HeaderItem, RowItem} from './Tabla.styles';

const Tabla = ({columns, data}) => {

    const [columnsData, setColumnsData] = useState(columns);
    const [rowData, setRowData] = useState(data);

    useEffect(() => {
        setColumnsData(columns);
        setRowData(data);
    },[columns,data])

    if(rowData && rowData.length > 0){
        rowData.map(item=>{
            item["mnos_order.created_at"] = item["mnos_order.created_at"].replace("T"," ").replace(".000Z","");
        })
    }

    return(
        <Wrapper>
            {columnsData.length && 
            <Content length={columnsData.length}>
                {columnsData.map(item=>(
                    <HeaderItem key={item.title} styles={item.style}>{item.title}</HeaderItem>
                ))}
                {rowData.map(row=>{
                    let i =0;
                    return(
                        columnsData.map(item=>(
                            <RowItem key={i} info={row[item.field]} number={i++} styles={item.style}>{row[item.field]}</RowItem>
                        ))
                    );
                }
                )}
            </Content>
            }
        </Wrapper>
    );
};

export default Tabla;

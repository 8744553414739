import React, {useState} from "react";
import { Wrapper, Content, LoadingDiv } from "./Actions.styles";
import { useUrlFetchPost, useUrlFetch } from "../../hooks/useUrlFetch";
import Button from "../Button";
import Loader from "../Loader";
import Tabla from "../Tabla";
import ShowForPermission from '../../ShowForPermission'


const Actions = (props) =>{
    
    const [tableList, setTableList] = useState(null);
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    const categoryId = queryParams.get('categoryId');
    var apiUrl, sessionVariable, datos, data_send,params;
    try{
        datos = props.location.state.item;
        apiUrl = datos["v_menus_b360.sourcedata"];
        params = datos["v_menus_b360.params"].replace("requestId",id);
        data_send = {
        "id":datos["v_menus_b360.apiConfigId"],
        "params":JSON.parse(params)
        }
        sessionVariable = window.location.pathname+window.location.search;
    }catch(error){
        datos = null;
        apiUrl = null;
        sessionVariable=null;
    }
    // reading from the api
    const apiData = useUrlFetchPost(apiUrl, data_send, sessionVariable);
    // data to show
    var data;

    try{
        data = apiData.state[0].buttons;
    }catch(error){
        data = null;
    }

    let i=0;

    return(
        
        <Wrapper>
            {apiData.loading && 
                <LoadingDiv>
                    <h3>Loading</h3>
                    <Loader/>
                </LoadingDiv>
            }
            {data && datos &&
                <>
                    <h1>{datos["v_menus_b360.nameMenu"]}</h1>
                    <Content>
                        {data.map(item=>
                        <ShowForPermission permission="button">
                            <Button data={item} key={"button"+(i++)} listHandler={(value)=>setTableList(value)}/>
                        </ShowForPermission>
                        )}
                    </Content>
                    {tableList}   
                    <br/>
                    <br/>
                </>
            }
        </Wrapper>
    );
};

export default Actions;

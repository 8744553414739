import { Roller } from "./Loader.styles";

const Loader = ({color = "{background: #2d68c4}"}) => (
  <Roller styles={color}>
    <br/>
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </Roller>
);

export default Loader;

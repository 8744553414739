import styled from "styled-components";

export const Wrapper = styled.div`
  width: 95%;
  align-self: center;
  justify-self:center;
  min-width: 340px;
  height: auto;
  padding: 10px 15px;
  background: var(--lightBlue);
  border-radius: 20px;
  margin: 15px 0 0 0;
  display: flex;
  align-content: center;
  justify-content: center;
  background: rgba(2,0,36,1);
 
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  h4 {
    display: block;
    width: auto;
    margin: 10px auto;
    padding: 0;
    color: var(--white);

    label{
      display: inline-block;
      max-width: 180px;
      white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;

    :hover{
      overflow: visible;
    }
    }
  }

`;

import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* background: var(--lightBlue); */
    /* align-items: center; */
    justify-content: center;

    h1{
        display: inline;
        color:#000000;
    }

    .prueba{
        display: flex;
        justify-content: center;
        
    }
`;

export const Content = styled.div`
    display: grid;
    flex-direction: column;
    align-self: center;
    // width: 80%;
    margin-left : 50px;
    grid-template-columns: fit-content(300px) 2px fit-content(600px) ;
    padding: 25px 15px;
    justify-content: center;

    background: #349de3;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 2.0px );
    -webkit-backdrop-filter: blur( 2.0px );
    border-radius: 10px;
    // border: 1px solid rgba( 255, 255, 255, 0.18 );

    .separator{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .centered{
        display: flex;
        align-items: left;
        justify-content: left;
    }
    
`;

export const DataInput = styled.div`
    padding: 10px 20px;
    margin: 0 0;
    align-self: center;
    

    .field-style{
        color: #000;
        font-weight: bold;
        text-decoration: underline;
    }

    .value-style{
        display: block;
        text-align: left;
        max-width: 600px;
        word-wrap: break-word;
        overflow: hidden; 
        white-space: nowrap; 
        text-overflow: ellipsis; 
        color: #111;
        ${({styles})=>styles} 


        /* Temporary styles */
        font-size: 15px;
        font-weight: bold;
        color: #fff;

        ${({customStyle})=>customStyle}
    }
`;

export const LoadingDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 20px;
    justify-self: center;
    align-self: center;
    h3{
        color: white;
    }
`;
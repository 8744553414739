import React, { useState } from "react";
import {
    Wrapper,
    Wrapper2,
    Wrapper3,
    Button,
    LoadingDiv,
    HeaderItem,
} from "./service.styles";
import DataGrid,
{
    Column,
    MasterDetail,
} from 'devextreme-react/data-grid';
import Tabs from 'devextreme-react/tabs';
import SelectBox from 'devextreme-react/select-box';
import {multiViewItems as companies} from './data2';
import service from './data';
import CompanyItem from './CompanyItem';
// import DetailTemplate from './DetailTemplate.js';
// import service from './data';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
// import ArrayStore from 'devextreme/data/array_store';
// import DataSource from 'devextreme/data/data_source';

// const detail = service.getDetail();

const employees = service.getEmployees();

const DetailTaps = (props) => {
    // const dataSource = getDetail(props.data.key)
    // var onValueChanged = this.onValueChanged.bind(this);
    // var onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);

    // const [selectedIndex, setselectedIndex] = useState(0)
    const [state, setstate] = useState({
        animationEnabled: true,
        swipeEnabled: true,
        loop: false
    })

    // const itemTitleRender = () => {
    //     return <span>hola</span>;
    //   };
    console.log("Data2...",companies)
    // const datatest = companies.map((item)=>({...item}))

    return (
        <>
            <Wrapper >
                <div id="tabs">
                    {/* <div className="caption">API</div> */}
                    <TabPanel
                        height={260}
                        width={'100%'}
                        dataSource={companies}
                        loop={state.loop}
                        itemComponent={CompanyItem}
                        animationEnabled={state.animationEnabled}
                        swipeEnabled={state.swipeEnabled}
                    />
                </div>
            </Wrapper>
        </>
    );

};



// function getDetail(key) {
//     return new DataSource({
//         store: new ArrayStore({
//             data: detail,
//             key: 'ID',
//         }),
//         filter: ['EmployeeID', '=', key],
//     });
// }

export default DetailTaps;

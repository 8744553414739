import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 22px;
    height: 22px;
    background: #ddd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #444;
    position: absolute;
    right: 5px;
    top: 5px;
`
import React from "react";
import { Wrapper } from "./ErrorPage.styles";
import error from "../../assets/images/Error.png";
import { Grid } from "@material-ui/core";
import ErrorRobot from '../Robot/robot-animated';

const ErrorPage = ({message}) => {
  return (
    // <Grid container spacing={0} alignItems="center" justifyContent="center">
    //     <Grid item xs={6}>
    //     <img src={error} />
    //     </Grid>
    // </Grid>
    <Wrapper>
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
        {/* <h1 style={{color:'white'}}>Lo Sentimos, En Este Momento Estamos Trabajando</h1> */}
      <h1>{message}</h1>
      <ErrorRobot />
    </div>
    </Wrapper>
  );
};

export default ErrorPage;

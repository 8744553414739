import React, {useState, useEffect} from "react";
import { Wrapper, LoadingDiv, FloatingButton } from "./incidencias.styles";
import Tabla from "../Tabla";
import Loader from "../Loader";
import { useUrlFetchPost } from "../../hooks/useUrlFetch";
import ShowForPermission from '../../ShowForPermission'
import  ButtonDownload  from "../ButtonDownload";
import { CSVDownload } from "react-csv";
import ReactDOM from "react-dom";


const Incidencias = (props) => {

  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  // const categoryId = queryParams.get('categoryId');
  var apiUrl, sessionVariable, datos, data_send, params;
  try {
    datos = props.location.state.item;
    apiUrl = datos["v_menus_b360.sourcedata"];
    params = datos["v_menus_b360.params"].replace("requestId", id);
    data_send = {
      id: datos["v_menus_b360.apiConfigId"],
      params: JSON.parse(params),
    };
    sessionVariable = window.location.pathname + window.location.search;
  } catch (error) {
    datos = null;
    apiUrl = null;
    sessionVariable = null;
  }

  // reading from the api
  const apiData = useUrlFetchPost(apiUrl, data_send, sessionVariable);
  // getting the json to visualize in the table
  useEffect(() => {
    const ApiHandler = () => {
      const apiDataPost = useUrlFetchPost(apiUrl, data_send, sessionVariable);
      
      useEffect(() => {
        if(apiDataPost.loading){
          setLoading(true);
          setTableData(null);
        }
        if(!apiDataPost.loading){
          setLoading(false);
          setTableData(apiDataPost.state[0])
        }
      },[apiDataPost])
      return <>
      </>;
    }
    let wrapper = document.createElement('div');
    ReactDOM.render(<ApiHandler />, wrapper);
  },[sessionVariable]);
  console.log(tableData, apiUrl,data_send,sessionVariable);

  const exportdata = () => {
    let wrapper = document.createElement('div');
        ReactDOM.render(<CSVDownload data={tableData.rows} />, wrapper);
        console.log("xsdf")
  }
  return (
    <Wrapper>
      {/* {apiData.error && <h1 className="errorHeader">There was a problem with the API, please contact the technical support.</h1>} */}
      {!tableData && !loading && (
        <h1 className="errorHeader">NO DATA</h1>
      )}
      {loading && (
        <LoadingDiv>
          <h3>Loading</h3>
          <Loader />
        </LoadingDiv>
      )}
      {tableData && datos && (
        <>
          <h1>{datos["v_menus_b360.nameMenu"]}</h1>
          <ShowForPermission permission={tableData["transform_permissions_export"]}>
            <FloatingButton>
              <ButtonDownload handler={()=>exportdata()} style={{alignSelf:"center", margin:"10px"}} text="Exportar Excel"/>
            </FloatingButton>
          </ShowForPermission>
          <ShowForPermission permission={tableData["transform_permissions"]}>
            <Tabla columns={tableData.columns} data={tableData.rows} />
          </ShowForPermission>
        </>
      )}
    </Wrapper>
  );
};

export default Incidencias;

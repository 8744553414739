import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

`;

export const Wrapper3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  
`;

export const Wrapper2 = styled.div`
  /* background: rgba(204, 225, 235, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); */
  background: rgba( 22, 20, 20, 0.55 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5px );
  -webkit-backdrop-filter: blur( 5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );

  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  padding: 0 20px;

`;

export const HeaderItem = styled.div`
  padding: 20px 16px;
  margin: 5px 2px 5px 2px;
  display: inline-block;
  color: var(--white);
  
`;

export const Button = styled.button`
  background-color: #008cba;
  border: 1px solid #008cba;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  padding: 10px;
  width: 200px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    background-color: white;
    color: black;
    border: 1px solid #4caf50;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`;

export const LoadingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 20px;
  justify-self: center;
  align-self: center;
  h3 {
    color: white;
  }
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 95%;
    align-self: center;
    justify-self:center;
    height: 95%;
    background: var(--lightBlue);
    border-radius: 20px;
    margin: 15px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(2,0,36,1);
   
    backdrop-filter: blur( 2.0px );
    -webkit-backdrop-filter: blur( 2.0px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
`;

export const Content = styled.div`
    padding: 10px 10px;
    width: 100%;
    display: flex;
`;

export const Table = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    text-align: center;

    :first-child {
        border-right: 1px dashed var(--white);
        border-bottom: 1px dashed var(--white);
    }
    :nth-child(2) {
        border-left: 1px dashed var(--white);
        border-bottom: 1px dashed var(--white);
    }
    :nth-child(3) {
        border-top: 1px dashed var(--white);
        border-right: 1px dashed var(--white);
    }
    :last-child {
        border-top: 1px dashed var(--white);
        border-left: 1px dashed var(--white);
    }

    h5{
        display: block;
        color: var(--white);
        height: auto;
        margin: 0 0 10px 0;
    }

    label{
        background: linear-gradient(
                to bottom, 
                rgba(255,255,255,0.15) 0%,
                rgba(255,255,255,0.3) 100%
        );
        border-radius: 10px;
        ${({status})=> status}         
    }

    /* This is for the ellipsis item */

    .ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .2s linear;
    white-space: nowrap;
    padding:.5rem 1rem;
    }

    .ellipsis:focus, .ellipsis:hover {
    color:transparent;
    }

    .ellipsis:focus:after,.ellipsis:hover:after{
        content:attr(data-text);
        overflow: visible;
        text-overflow: inherit;
        background: #fff;
        position: absolute;
        left:auto;
        top:auto;
        width: auto;
        max-width: 20rem;
        border: 1px solid #eaebec;
        padding: 0 .5rem;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
        white-space: normal;
        word-wrap: break-word;
        display:block;
        color:black;
        margin-top:-1.25rem;
        border-radius: 5px;
        ${({status})=> status}         
    }
`;


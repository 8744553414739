export const data = [
    {
      text: 'Website Re-Design Plan',
      startDate: new Date('2021-11-09T16:30:00.000Z'),
      endDate: new Date('2021-03-29T18:30:00.000Z'),
    }, {
      text: 'Book Flights to San Fran for Sales Trip',
      startDate: new Date('2021-11-09T19:00:00.000Z'),
      endDate: new Date('2021-11-09T20:00:00.000Z'),
      allDay: true,
    }, {
      text: 'Install New Router in Dev Room',
      startDate: new Date('2021-11-09T21:30:00.000Z'),
      endDate: new Date('2021-11-09T22:30:00.000Z'),
    }, {
      text: 'Approve Personal Computer Upgrade Plan',
      startDate: new Date('2021-11-15T17:00:00.000Z'),
      endDate: new Date('2021-11-15T18:00:00.000Z'),
    }, {
      text: 'Final Budget Review',
      startDate: new Date('2021-11-15T19:00:00.000Z'),
      endDate: new Date('2021-11-15T20:35:00.000Z'),
    }, {
      text: 'New Brochures',
      startDate: new Date('2021-11-15T21:30:00.000Z'),
      endDate: new Date('2021-11-15T22:45:00.000Z'),
    }, {
      text: 'Install New Database',
      startDate: new Date('2021-03-31T16:45:00.000Z'),
      endDate: new Date('2021-03-31T18:15:00.000Z'),
    }, {
      text: 'Approve New Online Marketing Strategy',
      startDate: new Date('2021-03-31T19:00:00.000Z'),
      endDate: new Date('2021-03-31T21:00:00.000Z'),
    }, {
      text: 'Upgrade Personal Computers',
      startDate: new Date('2021-03-31T22:15:00.000Z'),
      endDate: new Date('2021-03-31T23:30:00.000Z'),
    }, {
      text: 'Customer Workshop',
      startDate: new Date('2021-04-01T18:00:00.000Z'),
      endDate: new Date('2021-04-01T19:00:00.000Z'),
      allDay: true,
    }, {
      text: 'Prepare 2021 Marketing Plan',
      startDate: new Date('2021-04-01T18:00:00.000Z'),
      endDate: new Date('2021-04-01T20:30:00.000Z'),
    }, {
      text: 'Brochure Design Review',
      startDate: new Date('2021-04-01T21:00:00.000Z'),
      endDate: new Date('2021-04-01T22:30:00.000Z'),
    }, {
      text: 'Create Icons for Website',
      startDate: new Date('2021-04-02T17:00:00.000Z'),
      endDate: new Date('2021-04-02T18:30:00.000Z'),
    }, {
      text: 'Upgrade Server Hardware',
      startDate: new Date('2021-04-02T21:30:00.000Z'),
      endDate: new Date('2021-04-02T23:00:00.000Z'),
    }, {
      text: 'Submit New Website Design',
      startDate: new Date('2021-04-02T23:30:00.000Z'),
      endDate: new Date('2021-04-03T01:00:00.000Z'),
    }, {
      text: 'Launch New Website',
      startDate: new Date('2021-04-02T19:20:00.000Z'),
      endDate: new Date('2021-04-02T21:00:00.000Z'),
    },
  ];
  
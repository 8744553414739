import React from "react";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import { useUrlFetch } from "../../hooks/useUrlFetch";

// all available props
const theme = {
  opacity:0,
  background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#2d68c4",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#2d68c4",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};


const Bot = () => {

  const state = useUrlFetch('https://mocki.io/v1/87319d84-6b67-4c38-a9cc-c938643d15e3').state;



  return (
    <div className="chatbot">
      <ThemeProvider theme={theme} >
        {state.results &&
        <ChatBot 
        steps={state.results[0].questions} 
        headerTitle={state.results[0].title} 
        recognitionEnable={true}
        floating
        floatingIcon={<div className="icono_soporte"/>}
        />
      }
      </ThemeProvider>
    </div>
  );
};

export default Bot;

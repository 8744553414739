import "./assets/css/App.css";
import "./assets/css/button.css";
import "./assets/css/table.css";
import { useState, useEffect } from "react";
import DataGeneral from "./NewComponents/DataGeneral";
import Maps from "./NewComponents/Mapa/";
import Graphic from "./NewComponents/Graphic";
import History from "./NewComponents/History";
import TablaGeneral from './NewComponents/TablaGeneral';
import Actions from "./NewComponents/Actions";
import Incidencias from "./NewComponents/incidencias";
import Medidor from "./NewComponents/medidor";
import Ordenes from "./NewComponents/ordenes";
import Nacimiento from "./NewComponents/Nacimiento";
import GraphicTwo from "./NewComponents/GraphicTwo";
import LiveSeries from "./NewComponents/amchart1";
import ChartWithExport from "./NewComponents/amchartwithexport";
import TableGrafana from "./NewComponents/TableGrafana";
import RefreshButton from "./NewComponents/RefreshButton";
import Calendarys from "./NewComponents/Calendary";
import Indicators from "./NewComponents/Indicators";
import Servicios from "./NewComponents/Servicio";
import TableWithAction from "./NewComponents/TableWithAction/index"
import TP from "./NewComponents/TP";
import {
  BrowserRouter as Router,
  // Routes,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import Header from "./NewComponents/Header";
import MenuPrincipal from "./NewComponents/Menu-Principal/Menu";
import { GlobalStyle } from "./GlobalStyle";
import Home from "./NewComponents/Home";

import SplashScreen from "./NewComponents/SplashScreen";
import Bot from "./NewComponents/Chat/Bot";

import { isGlobalState } from "./session";
import Pivot from "./NewComponents/Pivot";
import Error404 from "./NewComponents/Error_404/Error404";
import ErrorPage from "./NewComponents/ErrorPage";
// import { useSessionStorage } from "./hooks/useSessionStorage";
import * as config from './config';
import {useUrlFetchPost, useUrlFetchPostOrdered} from './hooks/useUrlFetch';
import ShowForPermission from './ShowForPermission';

const components = {
  "Map": Maps,
  "ButtonsActions": Actions,
  "GeneralData": DataGeneral,
  "Table": Incidencias,
  "TableGrafana": TableGrafana,
  "Pivot": Nacimiento,
  "LiveSerie": LiveSeries,
  "ChartWithExport": ChartWithExport,
  "Calendary": Calendarys,
  "MasterDetail": Servicios,
  "LineCharts": Indicators,
  "Table": TP,
  "TablaGeneral":TablaGeneral,
  "TableWithAction": TableWithAction
}

const NewApp = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const categoryId = queryParams.get("categoryId");
  // const siteId = queryParams.get("siteId");
  
  const level1 = useUrlFetchPost(config.URL_LEVEL1,{"id":config['APICONFIGLVL1_'+String(categoryId)],"params":{"requestBtId":id}}, "LEVEL1/"+id+categoryId);
  // const level2 = useUrlFetchPost(config.URL_LEVEL2,{"id":config['APICONFIGLVL2_'+String(categoryId)],"params":{"requestBtId":id}}, "LEVEL2/"+id+categoryId);


  const token = queryParams.get("token");
  const [errorpage, setErrorpage] = useState(false);
  const [menuView, setMenu] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const menu = useUrlFetchPostOrdered(config.URL_LEVEL3,{"id":config['APICONFIGLVL3_'+String(categoryId)],"params":{"requestCategoryId":String(categoryId)}},"v_menus_b360.order", 1, "LEVEL3/"+id+categoryId);
  menu.state = menu.state[0];

  // var tokensession = sessionStorage.getItem("token");

  function validToken(params) {
    var myHeaders = new Headers();
    myHeaders.append("token", params);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://apiaws.bifrost.pe/api/AdministrationApp/info",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if ([ 400, 401, 404, 500, 501].includes(result.statusApi)) {
          setErrorpage(true);
          sessionStorage.clear();
          // sessionStorage.setItem("token", params);
          // sessionStorage.setItem("permission", JSON.stringify(result));
          console.log("va una vista general de errores");
          setErrorMessage(result.message+result.error);
          // <Redirect to={{      
          //   pathname: "/404"+window.location.search
          // }}/>
          // window.location.reload()
          
        } else {
          setErrorpage(false);
          // sessionStorage.clear();
          sessionStorage.setItem("token", params);
          sessionStorage.setItem("permission", JSON.stringify(result));
        }
      })
      .catch((error) => {
        setErrorpage(false);
        sessionStorage.setItem("token", "");
        sessionStorage.removeItem("permission");
        
      });
  }

  // if (tokensession == null || tokensession != token) {
  //   setErrorage(true);
  // }
  // const token = queryParams.get('token');
  // const [termsAccepted, setTermsAccepted] = useSessionStorage('token', token);
  const [loading, setLoading] = useState(false);
  const firstLoad = isGlobalState("hasLoadedForFirstTime/" + id + categoryId);

  // console.log(errorMessage);

  useEffect(() => {
  validToken(token);

    if (!firstLoad) {
      setLoading(true);
      if (token== null || token == "") {
        setErrorpage(true);
      }
    }
  }, [firstLoad]);

  return (
    <div className="BodyBackground">
     
      {errorpage ? 
       <div className="BigContainererror">
         <Router >
        <ErrorPage  message={errorMessage}/>
        <Redirect to={{      
            pathname: "/404"+window.location.search
          }}/>
      </Router>
        </div>
     
      : <>
         <div className="BigContainer">
        {loading &&  
        
        <SplashScreen  callback={() => setLoading(false)} />
        
        }
        {!loading && firstLoad && menu.state && (
          <Router>
            
            <div className="sidebar">
            {menuView && (
              <div className="menu-principal">
                   <button className="btn-menu-show-superior" onClick={() => setMenu(false)}>
                   <svg width="18" height="18" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 13.4761L13 1.47607M1 1.47607L13 13.4761" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                   </button>
                   <MenuPrincipal  />
                  
              </div>
         
            )}
            <div className="content-lvl2">
            {!menuView && (
             <button className="btn-menu-show" onClick={() => setMenu(true)}>
                 Menú
             </button>
         
            )}
                
            </div>

            </div>
           
            <div className="DataShow">
            <div className="card-info-content">
                    { level1.state && level1.state.map((item, id) => {
                    return (
                      <ShowForPermission permission={item["transform_permissions"]}>
                          <div className="card-info">
                                   <h3>{item.title}</h3>
                                   <span>{item.value}</span>
                          </div>
                      </ShowForPermission>
                    );
                  })}
                   </div> 
              <div className="DataContainer">
             
                <Switch>
                  {menu.state.map(item => (
                    <Route path={item["v_menus_b360.href"]} component={components[item["v_menus_b360.nameComponent"]]} />
                  ))}
                  {/* <Route exact path="/" component={Home} />
                  <Route path="/incidencias" component={Incidencias} />
                  <Route path="/data-general" component={DataGeneral} />
                  <Route path="/measurer" component={Medidor} />
                  <Route path="/orders" component={Ordenes} />
                  <Route path="/map" component={Maps} />
                  <Route path="/graphic1x2" component={Graphic} />
                  <Route path="/graphic2x1" component={GraphicTwo} />
                  <Route path="/history" component={History} />
                  <Route path="/pivot" component={Pivot} />
                  <Route path="/table-grafana" component={TableGrafana} />
                  <Route path="/nacimiento" component={Nacimiento} />
                  <Route path="/actions" component={Actions} />
                  <Route path="/live-serie" component={LiveSeries} /> */}
                  <Route path="*" component={Error404} />
                </Switch>
                <RefreshButton />
              </div>
              <Bot />
            </div>
            {/* <Support /> */}
            <GlobalStyle />
          </Router>
          
        )}
          </div>
        </>}
    
    </div>
  );
};

export default NewApp;


import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { Wrapper, LoadingDiv } from './TableGrafana.styles';
import Tabla from '../Tabla';
import Loader from '../Loader';
import {useUrlFetchPost} from '../../hooks/useUrlFetch';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);


const TableGrafana = (props) => {
    

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    // const categoryId = queryParams.get('categoryId');
    var apiUrl, sessionVariable, datos, data_send,params;
    try{
        datos = props.location.state.item;
        apiUrl = datos["v_menus_b360.sourcedata"];
        params = datos["v_menus_b360.params"].replace("requestId",id);
        data_send = {
        "id":datos["v_menus_b360.apiConfigId"],
        "params":JSON.parse(params)
        }
        sessionVariable = window.location.pathname+window.location.search;
    }catch(error){
        datos = null;
        apiUrl = null;
        sessionVariable=null;
    }
    
    // reading from the api
    const apiData = useUrlFetchPost(apiUrl, data_send, sessionVariable);
    // getting the json to visualize in the table
    const tableData = apiData.state[0];
    const chart = useRef(null);
    const [dataApi, setDataApi] = useState ()
    useEffect(() => {
      am4core.ready(function() {

        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end
        
        // Create chart instance
        var chart = am4core.create("chartdive", am4charts.XYChart);
        
        // Add data
        chart.data = generateChartData();
        
        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;
        
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        
        // Create series
        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "visits";
        series.dataFields.dateX = "date";
        series.strokeWidth = 2;
        series.minBulletDistance = 10;
        series.tooltipText = "{valueY}";
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.fillOpacity = 0.5;
        series.tooltip.label.padding(12,12,12,12)

        var series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "visits2";
        series2.dataFields.dateX = "date";
        series2.strokeWidth = 2;
        series2.minBulletDistance = 10;
        series2.tooltipText = "{valueY}";
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.background.cornerRadius = 20;
        series2.tooltip.background.fillOpacity = 0.5;
        series2.stroke = am4core.color("#F70DE5");
        series2.tooltip.label.padding(12,12,12,12)

        var series3 = chart.series.push(new am4charts.LineSeries());
        series3.dataFields.valueY = "visits3";
        series3.dataFields.dateX = "date";
        series3.strokeWidth = 2;
        series3.minBulletDistance = 10;
        series3.tooltipText = "{valueY}";
        series3.tooltip.pointerOrientation = "vertical";
        series3.tooltip.background.cornerRadius = 20;
        series3.tooltip.background.fillOpacity = 0.5;
        series3.stroke = am4core.color("#74F70D");
        series3.tooltip.label.padding(12,12,12,12)

        var series4 = chart.series.push(new am4charts.LineSeries());
        series4.dataFields.valueY = "visits4";
        series4.dataFields.dateX = "date";
        series4.strokeWidth = 2;
        series4.minBulletDistance = 10;
        series4.tooltipText = "{valueY}";
        series4.tooltip.pointerOrientation = "vertical";
        series4.tooltip.background.cornerRadius = 20;
        series4.tooltip.background.fillOpacity = 0.5;
        series4.stroke = am4core.color("#F70D0D");
        series4.tooltip.label.padding(12,12,12,12)
        // series4.stroke = am4core.color("#000000"),
        
        // Add scrollbar
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(series);
        
        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;
        
        function generateChartData() {
            var chartData = [];
            var firstDate = new Date();
            firstDate.setDate(firstDate.getDate() - 1000);
            var visits = 1200;
            var visits2 = 1200;
            var visits3 = 1200;
            var visits4 = 1200;

            for (var i = 0; i < 450; i++) {
                // we create date objects here. In your data, you can have date strings
                // and then set format of your dates using chart.dataDateFormat property,
                // however when possible, use date objects, as this will speed up chart rendering.
                var newDate = new Date(firstDate);
                newDate.setDate(newDate.getDate() + i);
                
                visits += Math.round((Math.random()<0.5?1:-1)*Math.random()*10);
                visits2 += Math.round((Math.random()<0.5?1:-1)*Math.random()*10);
                visits3 += Math.round((Math.random()<0.5?1:-1)*Math.random()*10);
                visits4 += Math.round((Math.random()<0.5?1:-1)*Math.random()*10);


        
                chartData.push({
                    date: newDate,
                    visits: visits,
                    visits2:visits2,
                    visits3:visits3,
                    visits4:visits4

                });
            }
            return chartData;
        }
        
        }); // end am4core.ready()
  }, [])
    return (
        <Wrapper>
            {/* {apiData.error && <h1 className="errorHeader">There was a problem with the API, please contact the technical support.</h1>} */}
            {!tableData && !apiData.loading &&
                <h1 className="errorHeader">NO DATA</h1>
            }
            {apiData.loading && 
            <LoadingDiv>
            <h3>Loading</h3>
            <Loader/>
            </LoadingDiv>
            }
            {tableData && datos && 
            <>
            <div id="chartdive" style={{ width: "100%", height: "300px" }}></div>
            <br></br>
            {/* <Tabla
                columns={tableData.columns}
                data={tableData.rows}
            /> */}
            </>}
        </Wrapper>
    );
};

export default TableGrafana;
import React from "react";
import { Wrapper } from "./medidor.styles";
import Tabla from "../Tabla";
import { useTableFetch } from "../../hooks/useTableFetch";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useUrlFetchPost } from "../../hooks/useUrlFetch";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Graphic = (props) => {
  // const {state, loading, error} = useTableFetch('https://mocki.io/v1/b5d5eb41-0f18-4363-883a-58ac1b6026f6');
  // console.log(state, loading, error);

  var apiUrl, sessionVariable, datos, data_send, params;
  try {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    datos = props.location.state.item;
    apiUrl = datos["v_menus_b360.sourcedata"];
    params = datos["v_menus_b360.params"].replace("requestId", id);
    data_send = {
      id: datos["v_menus_b360.apiConfigId"],
      params: JSON.parse(params),
    };
    sessionVariable = window.location.pathname + window.location.search;
  } catch (error) {
    datos = null;
    apiUrl = null;
    sessionVariable = null;
  }

  // reading from the api
  const apiData = useUrlFetchPost(apiUrl, data_send, sessionVariable);
  // data to show
  const data = apiData.state;
  console.log("rollex", data);

  const classes = useStyles();

  return (
    <Wrapper>
      <h1>Data de Consumo</h1>
      <div className={classes.root}>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={8}>
            <Paper className={classes.paper}>
              <iframe
                src="https://grafana.bifrost.pe/d-solo/_I9uEGN7z/time-series-two?orgId=1&var-deviceId=10004&from=1632294632494&to=1632899432494&panelId=4"
                width="400"
                height="200"
                frameborder="0"
              ></iframe>
            </Paper>
            <Paper className={classes.paper}>
              <iframe
                src="https://grafana.bifrost.pe/d-solo/_I9uEGN7z/time-series-two?orgId=1&var-deviceId=10004&from=1632294660309&to=1632899460309&panelId=2"
                width="400"
                height="200"
                frameborder="0"
              ></iframe>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Wrapper>
  );
};

export default Graphic;

import React from "react";
import { Wrapper } from "./medidor.styles";
import Tabla from "../Tabla";
import { useTableFetch } from "../../hooks/useTableFetch";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Graphic = () => {
  const { state, loading, error } = useTableFetch(
    "https://mocki.io/v1/b5d5eb41-0f18-4363-883a-58ac1b6026f6"
  );
  console.log(state, loading, error);

  const classes = useStyles();

  return (
    <Wrapper>
      <h1>Graficos 1x2</h1>
      <div className={classes.root}>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <iframe
                src="https://grafana.bifrost.pe/d-solo/QycwuoVnk/rollytest?orgId=1&var-idtest=37&from=1630374081826&to=1630395681826&panelId=2"
                width="400"
                height="200"
                frameborder="0"
              ></iframe>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <iframe
                src="https://grafana.bifrost.pe/d-solo/elIHrV4nz/cesartest?orgId=1&from=1630373388300&to=1630394988300&panelId=2"
                width="400"
                height="200"
                frameborder="0"
              ></iframe>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <iframe
                src="https://grafana.bifrost.pe/d-solo/QycwuoVnk/rollytest?orgId=1&var-idtest=22&from=1630512508066&to=1630534108066&panelId=4"
                width="400"
                height="200"
                frameborder="0"
              ></iframe>
            </Paper>
            <Paper className={classes.paper}>
              <iframe
                src="https://grafana.bifrost.pe/d-solo/QycwuoVnk/rollytest?orgId=1&var-idtest=22&from=1630512508066&to=1630534108066&panelId=4"
                width="400"
                height="200"
                frameborder="0"
              ></iframe>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Wrapper>
  );
};

export default Graphic;

// const OPENFASS_URL = 'https://openfaas.bifrost.pe/function/datebylevel';
// const OPENFASS_URL = '190.52.112.43:443/funtion/datebylevel';
// export const OPENFASS_URL = 'https://kong.bifrost.pe/openfaastest';
export const GOOGLE_API_KEY = 'AIzaSyAfYYol9nQkkf_7fPD8BslA6c0dlsWz7AA';

export const MODULES_URL = 'https://mocki.io/v1/b3af1f06-a35e-4d90-890d-6807abd45ad3';

export const URL_LEVEL1 = 'https://apiauth.bifrost.pe';
export const URL_LEVEL2 = 'https://apiauth.bifrost.pe';
export const URL_LEVEL3 = 'https://apiauth.bifrost.pe';
export const URL_LEVEL4 = 'https://mocki.io/v1/87319d84-6b67-4c38-a9cc-c938643d15e3';

export const URL_DATA_GENERAL = 'https://mocki.io/v1/47ce6fee-3445-4fb5-8165-792b48c867ad';
export const APICONFIGLVL1_1 = 2;
export const APICONFIGLVL2_1 = 3;
export const APICONFIGLVL3_1 = 6;
export const APICONFIGLVL4_1 = 0;

export const APICONFIGLVL1_2 = 2;
export const APICONFIGLVL2_2 = 3;
export const APICONFIGLVL3_2 = 6;
export const APICONFIGLVL4_2 = 0;

export const APICONFIGLVL1_3 = 2;
export const APICONFIGLVL2_3 = 36;
export const APICONFIGLVL3_3 = 6;
export const APICONFIGLVL4_3 = 0;

export const APICONFIGLVL1_8 = 37;
export const APICONFIGLVL2_8 = 38;
export const APICONFIGLVL3_8 = 6;
export const APICONFIGLVL4_8 = 0;


export const APICONFIGLVL1_9 = 2;
export const APICONFIGLVL2_9 = 3;
export const APICONFIGLVL3_9 = 6;
export const APICONFIGLVL4_9 = 0;

export const APICONFIGLVL1_10 = 2;
export const APICONFIGLVL2_10 = 7;
export const APICONFIGLVL3_10 = 6;
export const APICONFIGLVL4_10 = 0;


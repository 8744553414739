import React, { useState } from 'react';
import { createPortal } from "react-dom";
import styled from "styled-components";
import Chart from './chart';
import Loader from "../../Loader";

const SModalOverlay = styled.div`
  background-color: #999999;
  height: 100vh;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 500;
`;

const SModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 1%;
  width: 100%;
  height:100%;
  z-index: 1000;
`;

const SModal = styled.div`
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin: 1.875rem;
  min-width: 800px;
  position: relative;
  z-index: 100;
`;

const SHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0.9375rem 1.875rem 0.9375rem;
`;

const STitle = styled.h5`
  margin-bottom: 0.3125rem;
`;

const SButton = styled.button`
  border:none;
  color: #6D087C;
  cursor: pointer;
  font-weight: bold;
  padding: 0.9375rem;
  /*width: 80%;*/
  border-radius:9px;
  margin-bottom:20px;
  position: absolute;
  right: 35px;
`;

const SDescription = styled.span`
  color: #C1C1C1;
  text-align: center;
`;

const Modal = ({ isVisible, hideModal, rowSelected }) => {
  const [data, setDataSelet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('NO DATA AVAILABLE');
  function prueba() {
    if (rowSelected != null) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "id": 42,
        "params": {
          "rqIdAgenteModule": rowSelected['tagente_modulo.id_agente_modulo']
        }
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      // setLoading(true);
      fetch("https://api-gateway.bifrost.pe/apiauth", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === 500){
              let errorMessage = result["body"]["response"]
              setErrorMessage(errorMessage===''?'NO DATA AVAILABLE':errorMessage);
              setLoading(false);
              return;
            }
            setDataSelet(JSON.stringify(result));
            setLoading(false);
        })
        .catch(error => {
          console.log('error', error)
          setLoading(false);
        });
    }

  }
  prueba();
  return isVisible
    ? createPortal(
      <React.Fragment>
        <SModalOverlay />
        <SModalWrapper
          aria-modal={true}
          aria-hidden={true}
          tabIndex={-1}
          role="dialog"
        >
          <SModal>
            <SHeader>
              <SButton onClick={hideModal}>
                Close
              </SButton>
              <STitle>{rowSelected['tagente_modulo.nombre']}</STitle>
              <SDescription>
                {loading && (
                  <Loader />
                )}
                {data && !loading && <Chart datos={data} ></Chart>}
                {!data && !loading && <div style={{fontSize: "28px"}}><br/><br/>{errorMessage}</div>}
              </SDescription>
            </SHeader>
          </SModal>
        </SModalWrapper>
      </React.Fragment>,
      document.body,
    )
    : null;
};

export default Modal;
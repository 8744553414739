// import axios from 'axios';

  const apiSettings = {

    // fetchFromOpenfaas: async() => {
    //   const resp = await axios.get('https://kong.bifrost.pe/openfaastest%27')
    //   .then(res => {
    //       return res.data;
    //   })
    //   .then(data=>{
    //       let str = ''+data
    //       str = str.replace(/'/g,'"');
    //       let jsonObj =  JSON.parse(str);
    //       return jsonObj;
    //   })

    //   return resp;
    // },

    fetchTableTest : async(url) => {
      return await (await fetch(url)).json();
    },
    // fetchFromTest : async() => {
    //   return await (await fetch('https://mocki.io/v1/25380dd6-94bc-4ce7-9389-37ce47013206')).json();
    // },
    fetchFromUrl : async(url) => {
      return await (await fetch(url)).json();
    },
    postToUrl: async(url, data) => {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      return await (await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          "Authorization":"Bearer "+token,
        },
        body: JSON.stringify(data),
      })).json();
    },
    
  }
  export default apiSettings;
import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import { Wrapper, LoadingDiv } from "./ordenes.styles";
import API from '../../API';

// import Tabla from '../Tabla';
import Loader from "../Loader";
import { useUrlFetchPost } from "../../hooks/useUrlFetch";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ShowForPermission from "../../ShowForPermission";

// am4core.useTheme(am4themes_animated);

const Ordenes = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  var apiUrl, sessionVariable, datos, data_send, params;
  try {
    datos = props.location.state.item;
    apiUrl = datos["v_menus_b360.sourcedata"];
    params = datos["v_menus_b360.params"].replace("requestId", id);
    // parametros = params
    // console.log('PARAMEEEEEEEETROOOOOOOOOOOO', params, datos.v_menus_b360.apiConfigId)
    data_send = {
      id: datos["v_menus_b360.apiConfigId"],
      params: JSON.parse(params),
    };
    console.log('PARAAAAAMETROOOOOO', data_send)
    sessionVariable = window.location.pathname + window.location.search;
  } catch (error) {
    datos = null;
    apiUrl = null;
    sessionVariable = null;
  }

  const apiData = useUrlFetchPost(apiUrl, data_send, sessionVariable);
 
  const tableData = apiData.state[0];
  
  const chart = useRef(null);
  const [dataApi, setDataApi] = useState();
  console.log('rolly', tableData)

  useEffect(() => {
    am4core.ready(function() {
      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end

      var chart = am4core.create("chartdiv", am4charts.XYChart);

      var chartz = am4core.create("chartdive", am4charts.XYChart);

      chart.hiddenState.properties.opacity = 0;

      chart.padding(0, 0, 0, 0);

      chart.zoomOutButton.disabled = true;
      console.log("aaa");
      var data = [];
      var visits = 10;
      var visitse = 10;

      var i = 0;

      for (i = 0; i <= 10; i++) {
        visits = Math.round(Math.random() * (150 - 100) + 100);
        visitse = Math.round(Math.random() * (150 - 100) + 100);
        data.push({
          date: new Date().setSeconds(i - 30),
          value: visits,
          value2: visitse,
        });
        // console.log("VISIIIIIIIIITS", visits, visitse);
      }

      chart.data = data;

      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 30;
      dateAxis.dateFormats.setKey("second", "ss");
      dateAxis.periodChangeDateFormats.setKey("second", "[bold]h:mm a");
      dateAxis.periodChangeDateFormats.setKey("minute", "[bold]h:mm a");
      dateAxis.periodChangeDateFormats.setKey("hour", "[bold]h:mm a");
      dateAxis.renderer.inside = true;
      dateAxis.renderer.axisFills.template.disabled = true;
      dateAxis.renderer.ticks.template.disabled = true;
      dateAxis.title.text = "Tiempo (seg)";

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = false;
      valueAxis.interpolationDuration = 500;
      valueAxis.rangeChangeDuration = 500;
      valueAxis.renderer.inside = true;
      valueAxis.renderer.minLabelPosition = 0.05;
      valueAxis.renderer.maxLabelPosition = 0.95;
      valueAxis.renderer.axisFills.template.disabled = true;
      valueAxis.renderer.ticks.template.disabled = true;
      valueAxis.title.text = "Indice SNR";

      var range = valueAxis.axisRanges.create();
      range.value = 100;
      range.grid.stroke = am4core.color("#396478");
      range.grid.strokeWidth = 2;
      range.grid.strokeOpacity = 1;
      range.label.inside = true;
      range.label.text = "Umbral mínimo SNR";
      range.label.fill = range.grid.stroke;
      range.label.verticalCenter = "bottom";

      var range2 = valueAxis.axisRanges.create();
      range2.value = 140;
      range2.grid.stroke = am4core.color("#A96478");
      range2.grid.strokeWidth = 2;
      range2.grid.strokeOpacity = 1;
      range2.label.inside = true;
      range2.label.text = "Umbral máximo SNR";
      range2.label.fill = range2.grid.stroke;
      range2.label.verticalCenter = "bottom";

      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";
      // series.dataFields.category = "value";
      // console.log('DATAAAAA YYYYYYYYYYYYYYYYY', data)
      
      series.showTooltipOn = "always";
      series.tooltip.dx = 500;
      series.tooltip.dy = -6;

      series.name = "Downlink";
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.background.cornerRadius = 20;
      series.tooltip.background.fillOpacity = 1;
      series.tooltip.label.padding(12, 12, 12, 12);
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = am4core.color("#000000");
      
      console.log('CHARTTTTTT', chart)
      console.log('VALUEAXISSSSSSSS', valueAxis)
      console.log('SERIESSSSSS', series)
      console.log('DATAAAAAAAAA', data)
      var seriestwo = chart.series.push(new am4charts.LineSeries());
      seriestwo.dataFields.dateX = "date";
      seriestwo.dataFields.valueY = "value2";
      seriestwo.name = "Uplink";
      // seriestwo.tooltipText = "Uplink:{valueY}";
      seriestwo.showTooltipOn = "always";
      seriestwo.tooltip.dx = 300;
      seriestwo.tooltip.dy = -6;
      seriestwo.tooltip.pointerOrientation = "vertical";
      seriestwo.tooltip.background.cornerRadius = 20;
      seriestwo.tooltip.background.fillOpacity = 1;
      seriestwo.tooltip.label.padding(12, 12, 12, 12);
      seriestwo.tooltip.autoTextColor = false;
      seriestwo.tooltip.label.fill = am4core.color("#000000");
      seriestwo.interpolationDuration = 500;
      seriestwo.defaultState.transitionDuration = 0;
      seriestwo.tensionX = 0.8;
      seriestwo.stroke = am4core.color("#000000");

      chart.events.on("datavalidated", function() {
        dateAxis.zoom({ start: 1 / 15, end: 1.2 }, false, true);
      });

      dateAxis.interpolationDuration = 500;
      dateAxis.rangeChangeDuration = 500;

      chart.legend = new am4charts.Legend();

      //grafico 2

      chartz.hiddenState.properties.opacity = 0;

      chartz.padding(0, 0, 0, 0);

      chartz.zoomOutButton.disabled = true;
      console.log("aaa");
      var dataz = [];
      var visitss = 10;
      var visitsse = 10;
      var i = 0;

      for (i = 0; i <= 10; i++) {
        // Math.round(Math.random() * (150 - 100) + 100)
        visitss = Math.round(Math.random() * (12 - 1) + 1);
        visitsse = Math.round(Math.random() * (12 - 1) + 1);
        // visitss -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        // visitsse -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        dataz.push({
          date: new Date().setSeconds(i - 30),
          value3: visitss,
          value4: visitsse,
        });
      }

      chartz.data = dataz;

      var dateAxisz = chartz.xAxes.push(new am4charts.DateAxis());
      dateAxisz.renderer.grid.template.location = 0;
      dateAxisz.renderer.minGridDistance = 30;
      dateAxisz.dateFormats.setKey("second", "ss");
      dateAxisz.periodChangeDateFormats.setKey("second", "[bold]h:mm a");
      dateAxisz.periodChangeDateFormats.setKey("minute", "[bold]h:mm a");
      dateAxisz.periodChangeDateFormats.setKey("hour", "[bold]h:mm a");
      dateAxisz.renderer.inside = true;
      dateAxisz.renderer.axisFills.template.disabled = true;
      dateAxisz.renderer.ticks.template.disabled = true;
      dateAxisz.title.text = "Tiempo (seg)";

      // dateAxis.title.text = "Tiempo (seg)";

      var valueAxisz = chartz.yAxes.push(new am4charts.ValueAxis());
      valueAxisz.tooltip.disabled = true;
      valueAxisz.interpolationDuration = 500;
      valueAxisz.rangeChangeDuration = 500;
      valueAxisz.renderer.inside = true;
      valueAxisz.renderer.minLabelPosition = 0.05;
      valueAxisz.renderer.maxLabelPosition = 0.95;
      valueAxisz.renderer.axisFills.template.disabled = true;
      valueAxisz.renderer.ticks.template.disabled = true;
      valueAxisz.title.text = "MODCOD Valor";

      var range3 = valueAxisz.axisRanges.create();
      range3.value = 0;
      range3.grid.stroke = am4core.color("#396478");
      range3.grid.strokeWidth = 2;
      range3.grid.strokeOpacity = 1;
      range3.label.inside = true;
      range3.label.text = "Umbral mínimo MODCOD";
      range3.label.fill = range.grid.stroke;
      range3.label.verticalCenter = "bottom";

      var range4 = valueAxisz.axisRanges.create();
      range4.value = 10;
      range4.grid.stroke = am4core.color("#A96478");
      range4.grid.strokeWidth = 2;
      range4.grid.strokeOpacity = 1;
      range4.label.inside = true;
      range4.label.text = "Umbral máximo MODCOD";
      range4.label.fill = range2.grid.stroke;
      range4.label.verticalCenter = "bottom";

      var seriesz = chartz.series.push(new am4charts.LineSeries());
      seriesz.dataFields.dateX = "date";
      seriesz.dataFields.valueY = "value3";
      // seriesz.tooltipText = "UpCodeRate:{valueY}";
      seriesz.name = "UpCodeRate";
      seriesz.showTooltipOn = "always";
      seriesz.tooltip.dx = 600;
      seriesz.tooltip.dy = -6;
      seriesz.tooltip.pointerOrientation = "vertical";
      seriesz.tooltip.background.cornerRadius = 20;
      seriesz.tooltip.background.fillOpacity = 1;
      seriesz.tooltip.label.padding(12, 12, 12, 12);
      seriesz.tooltip.autoTextColor = false;
      seriesz.tooltip.label.fill = am4core.color("#000000");
      seriesz.interpolationDuration = 500;
      seriesz.defaultState.transitionDuration = 0;
      seriesz.tensionX = 0.8;

      var seriesztwo = chartz.series.push(new am4charts.LineSeries());
      seriesztwo.dataFields.dateX = "date";
      seriesztwo.dataFields.valueY = "value4";
      // seriesztwo.tooltipText = "DownlinkModCod:{valueY}";
      seriesztwo.name = "DownlinkModCod";
      seriesztwo.showTooltipOn = "always";
      seriesztwo.tooltip.dx = 300;
      seriesztwo.tooltip.dy = -6;
      seriesztwo.tooltip.pointerOrientation = "vertical";
      seriesztwo.tooltip.background.cornerRadius = 20;
      seriesztwo.tooltip.background.fillOpacity = 1;
      seriesztwo.tooltip.label.padding(12, 12, 12, 12);
      seriesztwo.tooltip.autoTextColor = false;
      seriesztwo.tooltip.label.fill = am4core.color("#000000");
      seriesztwo.interpolationDuration = 500;
      seriesztwo.defaultState.transitionDuration = 0;
      seriesztwo.tensionX = 0.8;
      seriesztwo.stroke = am4core.color("#000000");

      chartz.events.on("datavalidated", function() {
        dateAxisz.zoom({ start: 1 / 15, end: 1.2 }, false, true);
      });

      dateAxisz.interpolationDuration = 500;
      dateAxisz.rangeChangeDuration = 500;

      chartz.legend = new am4charts.Legend();

      document.addEventListener(
        "visibilitychange",
        function() {
          if (document.hidden) {
            if (interval) {
              clearInterval(interval);
            }
          } else {
            startInterval();
          }
        },
        false
      );

      // add data
      var interval;
      var init_send = 0;
      var siteId = "270SPML1149";
      var url = 'https://apiauth.bifrost.pe'
      var data_enviado = data_send
      function startInterval() {
        interval = setInterval(function() {
          init_send += 1;
          if (init_send >= 6) {
            clearInterval(interval);
            return false;
          } else {
            API.postToUrl(url,data_enviado).then((response) => {
              console.log('MAAAAAAAAAAAAAAAAAA',response.response[0].value)
              var DataOriginal = JSON.parse(response.response[0].value);
              try {
                var DatadownlinkEsNo = DataOriginal.downlinkEsNo;
                var DatadownlinkEsNo2 = DataOriginal.uplinkEsNo;
                var downlinkModcod = 0;
                var uplinkCodeRate = 0;
                switch (DataOriginal.uplinkCodeRate) {
                  case "9/10":
                    uplinkCodeRate = 10;
                    break;
                  case "5/6":
                    uplinkCodeRate = 9;
                    break;
                  case "4/5":
                    uplinkCodeRate = 8;
                    break;
                  case "3/4":
                    uplinkCodeRate = 7;
                    break;
                  case "2/3":
                    uplinkCodeRate = 6;
                    break;
                  case "3/5":
                    uplinkCodeRate = 5;
                    break;
                  case "1/2":
                    uplinkCodeRate = 4;
                    break;
                  default:
                    uplinkCodeRate = 0;
                    break;
                }
              } catch (error) {
                var DatadownlinkEsNo = 0;
                uplinkCodeRate = 0;
                downlinkModcod = 0;
                DatadownlinkEsNo2 = 0;
              }

              if (DatadownlinkEsNo != undefined) {
                console.log(
                  "YAAAAAAAAAAA",
                  DataOriginal.downlinkModcod.includes("5/6")
                );
                if (DataOriginal.downlinkModcod.includes("9/10")) {
                  downlinkModcod = 10;
                } else if (DataOriginal.downlinkModcod.includes("5/6")) {
                  downlinkModcod = 9;
                  console.log("entrooooooo", downlinkModcod);
                } else if (DataOriginal.downlinkModcod.includes("4/5")) {
                  downlinkModcod = 8;
                  console.log("entrooooooo", downlinkModcod);
                } else if (DataOriginal.downlinkModcod.includes("3/4")) {
                  downlinkModcod = 7;
                  console.log("entrooooooo", downlinkModcod);
                } else if (DataOriginal.downlinkModcod.includes("2/3")) {
                  downlinkModcod = 6;
                  console.log("entrooooooo", downlinkModcod);
                } else if (DataOriginal.downlinkModcod.includes("3/5")) {
                  downlinkModcod = 5;
                  console.log("entrooooooo", downlinkModcod);
                } else if (DataOriginal.downlinkModcod.includes("1/2")) {
                  downlinkModcod = 4;
                  console.log("entrooooooo", downlinkModcod);
                } else {
                  downlinkModcod = 0;
                }
                // switch (DataOriginal) {
                //     case DataOriginal.downlinkModcod.includes('9/10'):
                //       downlinkModcod=10;
                //       break;
                //     case  DataOriginal.downlinkModcod.includes('5/6'):
                //       downlinkModcod=9;
                //       console.log('entrooooooo',downlinkModcod)
                //       break;
                //     case  DataOriginal.downlinkModcod.includes('4/5'):
                //       downlinkModcod=8;
                //       break;
                //     case  DataOriginal.downlinkModcod.includes('3/4'):
                //       downlinkModcod=7;
                //       break;
                //     case  DataOriginal.downlinkModcod.includes('2/3'):
                //       downlinkModcod=6;
                //       break;
                //     case  DataOriginal.downlinkModcod.includes('3/5'):
                //       downlinkModcod=5;
                //       break;
                //     case  DataOriginal.downlinkModcod.includes('1/2'):
                //       downlinkModcod=4;
                //       break;

                //     default:
                //       downlinkModcod=0;
                //       break;
                //   }
                var segundosAnterior = 0;
                // visits =
                // visits + Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 5);
                var lastdataItem = series.dataItems.getIndex(
                  series.dataItems.length - 1
                );
                chart.addData(
                  {
                    date: new Date(lastdataItem.dateX.getTime() + 5000),
                    value: parseInt(DatadownlinkEsNo),
                    value2: parseInt(DatadownlinkEsNo2),
                    value3: uplinkCodeRate,
                    value4: downlinkModcod,
                  },
                  1
                );
                console.log(
                  "dataaaaaaaaaa",
                  DatadownlinkEsNo,
                  DatadownlinkEsNo2,
                  uplinkCodeRate,
                  downlinkModcod
                );
                series.tooltipText = "Downlinks : " + DatadownlinkEsNo;
                seriestwo.tooltipText = "Uplink : " + DatadownlinkEsNo2;
                seriesz.tooltipText = "UpCodeRate : " + uplinkCodeRate;
                seriesztwo.tooltipText = "DownlinkModCod : " + downlinkModcod;


                var lastdataItemz = seriesz.dataItems.getIndex(
                  seriesz.dataItems.length - 1
                );

                console.log("jeeeeeeeeeeeeee");
                chartz.addData(
                  {
                    date: new Date(lastdataItemz.dateX.getTime() + 5000),
                    value: parseInt(DatadownlinkEsNo),
                    value2: parseInt(DatadownlinkEsNo2),
                    value3: uplinkCodeRate,
                    value4: downlinkModcod,
                  },
                  1
                );
                // data.push({ date: new Date().setSeconds(0), value: parseInt(DatadownlinkEsNo), value2 : parseInt(DatadownlinkEsNo2), value3 : visits, value4 : downlinkModcod})
              }});
            // console.log('JIJIIIIIIIIIIIII', data)
            // fetch("https://onpremise.bifrost.pe/api/ovt?siteId=" + siteId)
            //   .then((response) => response.text())
              // .then(function(response) {
                
              // });
          }
          //dataa

          //data
        }, 6000);
      }

      // for (let index = 0; index < 5; index++) {
      //     // const element = array[index];
      startInterval();

      // }

      // all the below is optional, makes some fancy effects
      // gradient fill of the series
      series.fillOpacity = 1;
      var gradient = new am4core.LinearGradient();
      gradient.addColor(chart.colors.getIndex(0), 0.2);
      gradient.addColor(chart.colors.getIndex(0), 0);
      series.fill = gradient;

      // this makes date axis labels to fade out
      dateAxis.renderer.labels.template.adapter.add("fillOpacity", function(
        fillOpacity,
        target
      ) {
        var dataItem = target.dataItem;
        return dataItem.position;
      });

      // need to set this, otherwise fillOpacity is not changed and not set
      dateAxis.events.on("validated", function() {
        am4core.iter.each(dateAxis.renderer.labels.iterator(), function(label) {
          label.fillOpacity = label.fillOpacity;
        });
      });

      // this makes date axis labels which are at equal minutes to be rotated
      dateAxis.renderer.labels.template.adapter.add("rotation", function(
        rotation,
        target
      ) {
        var dataItem = target.dataItem;
        if (
          dataItem.date &&
          dataItem.date.getTime() ==
            am4core.time
              .round(new Date(dataItem.date.getTime()), "minute")
              .getTime()
        ) {
          target.verticalCenter = "middle";
          target.horizontalCenter = "left";
          return -90;
        } else {
          target.verticalCenter = "bottom";
          target.horizontalCenter = "middle";
          return 0;
        }
      });

      // bullet at the front of the line
      var bullet = series.createChild(am4charts.CircleBullet);
      bullet.circle.radius = 5;
      bullet.fillOpacity = 1;
      bullet.fill = chart.colors.getIndex(0);
      bullet.isMeasured = false;

      series.events.on("validated", function() {
        bullet.moveTo(series.dataItems.last.point);
        bullet.validatePosition();
      });

      // grafico 2

      seriesz.fillOpacity = 1;
      var gradient = new am4core.LinearGradient();
      gradient.addColor(chartz.colors.getIndex(0), 0.2);
      gradient.addColor(chartz.colors.getIndex(0), 0);
      seriesz.fill = gradient;

      // this makes date axis labels to fade out
      dateAxisz.renderer.labels.template.adapter.add("fillOpacity", function(
        fillOpacity,
        target
      ) {
        var dataItem = target.dataItem;
        return dataItem.position;
      });

      // need to set this, otherwise fillOpacity is not changed and not set
      dateAxisz.events.on("validated", function() {
        am4core.iter.each(dateAxisz.renderer.labels.iterator(), function(
          label
        ) {
          label.fillOpacity = label.fillOpacity;
        });
      });

      // this makes date axis labels which are at equal minutes to be rotated
      dateAxisz.renderer.labels.template.adapter.add("rotation", function(
        rotation,
        target
      ) {
        var dataItem = target.dataItem;
        if (
          dataItem.date &&
          dataItem.date.getTime() ==
            am4core.time
              .round(new Date(dataItem.date.getTime()), "minute")
              .getTime()
        ) {
          target.verticalCenter = "middle";
          target.horizontalCenter = "left";
          return -90;
        } else {
          target.verticalCenter = "bottom";
          target.horizontalCenter = "middle";
          return 0;
        }
      });

      // bullet at the front of the line
      var bullets = seriesz.createChild(am4charts.CircleBullet);
      bullets.circle.radius = 5;
      bullets.fillOpacity = 1;
      bullets.fill = chart.colors.getIndex(0);
      bullets.isMeasured = false;

      seriesz.events.on("validated", function() {
        bullets.moveTo(seriesz.dataItems.last.point);
        bullets.validatePosition();
      });

      /////////////////////////////////////////////////////////////
      seriestwo.fillOpacity = 1;
      var gradienttwo = new am4core.LinearGradient();
      gradienttwo.addColor(chart.colors.getIndex(0), 0.2);
      gradienttwo.addColor(chart.colors.getIndex(0), 0);
      seriestwo.fill = gradient;

      // this makes date axis labels to fade out

      // need to set this, otherwise fillOpacity is not changed and not set

      // this makes date axis labels which are at equal minutes to be rotated

      // bullet at the front of the line
      var bulletss = seriestwo.createChild(am4charts.CircleBullet);
      bulletss.circle.radius = 5;
      bulletss.fillOpacity = 1;
      bulletss.fill = chart.colors.getIndex(0);
      bulletss.isMeasured = false;

      seriestwo.events.on("validated", function() {
        bulletss.moveTo(seriestwo.dataItems.last.point);
        bulletss.validatePosition();
      });

      /////////////////////////////////////////////////////////////

      //////////////////////////222////////////////////////////////
      seriesztwo.fillOpacity = 1;
      var gradientztwo = new am4core.LinearGradient();
      gradientztwo.addColor(chart.colors.getIndex(0), 0.2);
      gradientztwo.addColor(chart.colors.getIndex(0), 0);
      seriesztwo.fill = gradientztwo;

      // this makes date axis labels to fade out

      // need to set this, otherwise fillOpacity is not changed and not set

      // this makes date axis labels which are at equal minutes to be rotated

      // bullet at the front of the line
      var bulletsss = seriesztwo.createChild(am4charts.CircleBullet);
      bulletsss.circle.radius = 5;
      bulletsss.fillOpacity = 1;
      bulletsss.fill = chart.colors.getIndex(0);
      bulletsss.isMeasured = false;

      seriesztwo.events.on("validated", function() {
        bulletsss.moveTo(seriesztwo.dataItems.last.point);
        bulletsss.validatePosition();
      });
      /////////////////////////222/////////////////////////////////
    });
  }, []);
  return (
    <Wrapper>
      <ShowForPermission permission="graficoslivesnr">
        {/* {apiData.error && <h1 className="errorHeader">There was a problem with the API, please contact the technical support.</h1>} */}
        <h1>SNR DE TERMINAL</h1>
        <div
          id="chartdiv"
          style={{ width: "100%", height: "275px", background: "white" }}
        ></div>
      </ShowForPermission>

      <ShowForPermission permission="graficoslivemodcod">

        <h1>MODCOD DE TERMINAL</h1>
        <div
          id="chartdive"
          style={{ width: "100%", height: "275px", background: "white" }}
        ></div>
      </ShowForPermission>
    </Wrapper>
  );
};

export default Ordenes;

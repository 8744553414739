import React from "react";

// Styles
import { Wrapper, Content, HeaderItem, RowItem, ContentOut } from "./Tabla.styles";

const Tabla = ({ columns, data }) => {
  let column = 0;
  return (
    <Wrapper>
      {columns.length && (
      <ContentOut>
        <Content length={columns.length}>
          {columns.map((item) => (
            <HeaderItem key={item.title}>{item.title}</HeaderItem>
            ))}
          {/* {console.log(columns, data)} */}
        </Content>
        {data.map((row) => {
          let i = 0;
          return (
            <Content className={`${(column++)!=0?"column":""}`}>
            {columns.map((item) => (
              <RowItem key={i} info={row[item.field]} number={i++}>
              {row[item.field]}
              </RowItem>
              ))}
              </Content>
          )
          })}
             
            
      </ContentOut>
      )}
    </Wrapper>
  );
};

export default Tabla;

import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    display: grid;
    align-self: center;
    padding: 20px 15px;
    grid-template-columns: fit-content(200px) fit-content(200px) fit-content(200px)  fit-content(200px);
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    background: rgba( 204, 225, 235, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 2.0px );
    -webkit-backdrop-filter: blur( 2.0px );
    border-radius: 10px;
    // border: 1px solid rgba( 255, 255, 255, 0.18 );
    align-items: center;
    justify-content: center;
    
`;

export const TableContent = styled.div`
    display: flex;
    align-self: center;
    padding: 20px 25px;
    margin-top: 20px;
    /* grid-template-columns: fit-content(200px) fit-content(200px) fit-content(200px)  fit-content(200px); */
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    background: rgba( 244, 245, 235, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    /* backdrop-filter: blur( 2.0px );
    -webkit-backdrop-filter: blur( 2.0px ); */
    border-radius: 10px;
    // border: 1px solid rgba( 255, 255, 255, 0.18 );
    align-items: center;
    justify-content: center;
    
`;

export const LoadingDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 20px;
    justify-self: center;
    align-self: center;
    h3{
        color: white;
    }
`;
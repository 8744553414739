import {useState, useEffect} from 'react';
import { Wrapper, Content } from "./ProgressBar.styles";

const ProgressBar = (props)=>{
    const [loadingTime, ] = useState(5000);
    const [hasFinished, setHasFinished] = useState(false);
    useEffect(() => {
        // setInterval(() => {
        //     setHasFinished(true);
        // },loadingTime)
    },[loadingTime])
    return (
        <Wrapper>
            {!hasFinished &&
                <Content loadTime={loadingTime}>
                </Content>
            }
        </Wrapper>
    )
}

export default ProgressBar;
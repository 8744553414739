import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Wrapper} from "./RefreshButton.styles";

const RefreshButton = () => {

  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const handler = () => {
    setLoading(true);
    console.log("yeah")
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    console.log(location);

    // Remove ALL elements that starts with the current path on browser
    /**
     * We create the regular expresion and add a \ because window.location.search starts with a '?' that is a special character
     */
    var regexString = window.location.pathname+"\\"+window.location.search;
    var regex = new RegExp(regexString);
    var n = sessionStorage.length;
    while(n--) {
      var key = sessionStorage.key(n);
      if(regex.test(key)) {
        sessionStorage.removeItem(key);
      }  
    }
    window.location.reload();
  }

  return(
    <Wrapper onClick={handler}>
  <i className={`fa fa-refresh ${loading?'fa-spin':''}`}></i>
  </Wrapper>
  );
}

export default RefreshButton;

export const customers = [{
    ID: 1,
    NombreContrato: 'Super Mart of the West',
    Titulo: '702 SW 8th Street',
    Descripcion: 'Bentonville',
    FechaInicio: 'Arkansas',
    Duracion: 72716,
    FechaFin: '(800) 555-2797',
    Estado: '(800) 555-2171',
    Website: 'http://www.nowebsitesupermart.com',
  }, {
    ID: 2,
    NombreContrato: 'Electronics Depot',
    Titulo: '2455 Paces Ferry Road NW',
    Descripcion: 'Atlanta',
    FechaInicio: 'Georgia',
    Duracion: 30339,
    FechaFin: '(800) 595-3232',
    Estado: '(800) 595-3231',
    Website: 'http://www.nowebsitedepot.com',
  }, {
    ID: 3,
    NombreContrato: 'K&S Music',
    Titulo: '1000 Nicllet Mall',
    Descripcion: 'Minneapolis',
    FechaInicio: 'Minnesota',
    Duracion: 55403,
    FechaFin: '(612) 304-6073',
    Estado: '(612) 304-6074',
    Website: 'http://www.nowebsitemusic.com',
  }, {
    ID: 4,
    NombreContrato: "Tom's Club",
    Titulo: '999 Lake Drive',
    Descripcion: 'Issaquah',
    FechaInicio: 'Washington',
    Duracion: 98027,
    FechaFin: '(800) 955-2292',
    Estado: '(800) 955-2293',
    Website: 'http://www.nowebsitetomsclub.com',
  }, {
    ID: 5,
    NombreContrato: 'E-Mart',
    Titulo: '3333 Beverly Rd',
    Descripcion: 'Hoffman EFechaInicios',
    FechaInicio: 'Illinois',
    Duracion: 60179,
    FechaFin: '(847) 286-2500',
    Estado: '(847) 286-2501',
    Website: 'http://www.nowebsiteemart.com',
  }, {
    ID: 6,
    NombreContrato: 'Walters',
    Titulo: '200 Wilmot Rd',
    Descripcion: 'Deerfield',
    FechaInicio: 'Illinois',
    Duracion: 60015,
    FechaFin: '(847) 940-2500',
    Estado: '(847) 940-2501',
    Website: 'http://www.nowebsitewalters.com',
  }, {
    ID: 7,
    NombreContrato: 'StereoShack',
    Titulo: '400 Commerce S',
    Descripcion: 'Fort Worth',
    FechaInicio: 'Texas',
    Duracion: 76102,
    FechaFin: '(817) 820-0741',
    Estado: '(817) 820-0742',
    Website: 'http://www.nowebsiteshack.com',
  }, {
    ID: 8,
    NombreContrato: 'Circuit Town',
    Titulo: '2200 Kensington Court',
    Descripcion: 'Oak Brook',
    FechaInicio: 'Illinois',
    Duracion: 60523,
    FechaFin: '(800) 955-2929',
    Estado: '(800) 955-9392',
    Website: 'http://www.nowebsitecircuittown.com',
  }, {
    ID: 9,
    NombreContrato: 'Premier Buy',
    Titulo: '7601 Penn Avenue South',
    Descripcion: 'Richfield',
    FechaInicio: 'Minnesota',
    Duracion: 55423,
    FechaFin: '(612) 291-1000',
    Estado: '(612) 291-2001',
    Website: 'http://www.nowebsitepremierbuy.com',
  }, {
    ID: 10,
    NombreContrato: 'ElectrixMax',
    Titulo: '263 Shuman Blvd',
    Descripcion: 'Naperville',
    FechaInicio: 'Illinois',
    Duracion: 60563,
    FechaFin: '(630) 438-7800',
    Estado: '(630) 438-7801',
    Website: 'http://www.nowebsiteelectrixmax.com',
  }, {
    ID: 11,
    NombreContrato: 'Video Emporium',
    Titulo: '1201 Elm Street',
    Descripcion: 'Dallas',
    FechaInicio: 'Texas',
    Duracion: 75270,
    FechaFin: '(214) 854-3000',
    Estado: '(214) 854-3001',
    Website: 'http://www.nowebsitevideoemporium.com',
  }, {
    ID: 12,
    NombreContrato: 'Screen Shop',
    Titulo: '1000 Lowes Blvd',
    Descripcion: 'Mooresville',
    FechaInicio: 'North Carolina',
    Duracion: 28117,
    FechaFin: '(800) 445-6937',
    Estado: '(800) 445-6938',
    Website: 'http://www.nowebsitescreenshop.com',
  },{
    ID: 13,
    NombreContrato: 'Super Mart of the West',
    Titulo: '702 SW 8th Street',
    Descripcion: 'Bentonville',
    FechaInicio: 'Arkansas',
    Duracion: 72716,
    FechaFin: '(800) 555-2797',
    Estado: '(800) 555-2171',
    Website: 'http://www.nowebsitesupermart.com',
  }, {
    ID: 14,
    NombreContrato: 'Electronics Depot',
    Titulo: '2455 Paces Ferry Road NW',
    Descripcion: 'Atlanta',
    FechaInicio: 'Georgia',
    Duracion: 30339,
    FechaFin: '(800) 595-3232',
    Estado: '(800) 595-3231',
    Website: 'http://www.nowebsitedepot.com',
  }, {
    ID: 15,
    NombreContrato: 'K&S Music',
    Titulo: '1000 Nicllet Mall',
    Descripcion: 'Minneapolis',
    FechaInicio: 'Minnesota',
    Duracion: 55403,
    FechaFin: '(612) 304-6073',
    Estado: '(612) 304-6074',
    Website: 'http://www.nowebsitemusic.com',
  }, {
    ID: 16,
    NombreContrato: "Tom's Club",
    Titulo: '999 Lake Drive',
    Descripcion: 'Issaquah',
    FechaInicio: 'Washington',
    Duracion: 98027,
    FechaFin: '(800) 955-2292',
    Estado: '(800) 955-2293',
    Website: 'http://www.nowebsitetomsclub.com',
  }, {
    ID: 17,
    NombreContrato: 'E-Mart',
    Titulo: '3333 Beverly Rd',
    Descripcion: 'Hoffman EFechaInicios',
    FechaInicio: 'Illinois',
    Duracion: 60179,
    FechaFin: '(847) 286-2500',
    Estado: '(847) 286-2501',
    Website: 'http://www.nowebsiteemart.com',
  }, {
    ID: 18,
    NombreContrato: 'Walters',
    Titulo: '200 Wilmot Rd',
    Descripcion: 'Deerfield',
    FechaInicio: 'Illinois',
    Duracion: 60015,
    FechaFin: '(847) 940-2500',
    Estado: '(847) 940-2501',
    Website: 'http://www.nowebsitewalters.com',
  }, {
    ID: 19,
    NombreContrato: 'StereoShack',
    Titulo: '400 Commerce S',
    Descripcion: 'Fort Worth',
    FechaInicio: 'Texas',
    Duracion: 76102,
    FechaFin: '(817) 820-0741',
    Estado: '(817) 820-0742',
    Website: 'http://www.nowebsiteshack.com',
  }, {
    ID: 20,
    NombreContrato: 'Circuit Town',
    Titulo: '2200 Kensington Court',
    Descripcion: 'Oak Brook',
    FechaInicio: 'Illinois',
    Duracion: 60523,
    FechaFin: '(800) 955-2929',
    Estado: '(800) 955-9392',
    Website: 'http://www.nowebsitecircuittown.com',
  }, {
    ID: 21,
    NombreContrato: 'Premier Buy',
    Titulo: '7601 Penn Avenue South',
    Descripcion: 'Richfield',
    FechaInicio: 'Minnesota',
    Duracion: 55423,
    FechaFin: '(612) 291-1000',
    Estado: '(612) 291-2001',
    Website: 'http://www.nowebsitepremierbuy.com',
  }, {
    ID: 22,
    NombreContrato: 'ElectrixMax',
    Titulo: '263 Shuman Blvd',
    Descripcion: 'Naperville',
    FechaInicio: 'Illinois',
    Duracion: 60563,
    FechaFin: '(630) 438-7800',
    Estado: '(630) 438-7801',
    Website: 'http://www.nowebsiteelectrixmax.com',
  }, {
    ID: 23,
    NombreContrato: 'Video Emporium',
    Titulo: '1201 Elm Street',
    Descripcion: 'Dallas',
    FechaInicio: 'Texas',
    Duracion: 75270,
    FechaFin: '(214) 854-3000',
    Estado: '(214) 854-3001',
    Website: 'http://www.nowebsitevideoemporium.com',
  }, {
    ID: 24,
    NombreContrato: 'Screen Shop',
    Titulo: '1000 Lowes Blvd',
    Descripcion: 'Mooresville',
    FechaInicio: 'North Carolina',
    Duracion: 28117,
    FechaFin: '(800) 445-6937',
    Estado: '(800) 445-6938',
    Website: 'http://www.nowebsitescreenshop.com',
  }];
  

import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const Monitoreo = (props) => {

    var datosOriginales = JSON.parse(props.datos);
    let dataMonitor = [];
    if (datosOriginales) {
        console.log(datosOriginales.response);
        for (let i = 0; i < datosOriginales.response.length; i++) {
            dataMonitor = datosOriginales.response[i]
            // console.log('data', datosOriginales.response[i].tagente_datos.datos']);
        }
    }

    const chart = useRef(null);


    useLayoutEffect(() => {
        setInterval(() => {

        }, 5000);

        let x = am4core.create("chartdiv", am4charts.XYChart);

        x.paddingRight = 20;

        let data = [];
        let visits = 10;

        if (datosOriginales) {
            console.log(datosOriginales.response);
            var last = 0;
            for (let i = 0; i < datosOriginales.response[0].length; i++) {
                // let date = new Date(datosOriginales.response[0][i]['tagente_datos.utimestamp'] * 1000);
                // let dateDay = date.getDate();
                try {
                    if (datosOriginales.response[0][i]['tagente_datos.datos'] <= 0) {
                        data.push({ date: new Date(datosOriginales.response[0][i]['tagente_datos.utimestamp'] * 1000), name: datosOriginales.response[0][i - 1]['tagente_datos.datos'] + i-1, value: datosOriginales.response[0][i - 1]['tagente_datos.datos'] });
                    } else {

                        data.push({ date: new Date(datosOriginales.response[0][i]['tagente_datos.utimestamp'] * 1000), name: datosOriginales.response[0][i]['tagente_datos.datos'] + i, value: datosOriginales.response[0][i]['tagente_datos.datos'] });
                    }

                } catch (error) {
                    data.push({ date: new Date(datosOriginales.response[0][i]['tagente_datos.utimestamp'] * 1000), name: datosOriginales.response[0][i]['tagente_datos.datos'] + i, value: datosOriginales.response[0][i]['tagente_datos.datos'] });

                }
            }
        }

        console.log('DATA MONITOR', data)
        x.data = data;


        let dateAxis = x.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.dateFormats.setKey("day", "yyyy/MM/dd");
        dateAxis.periodChangeDateFormats.setKey(
            "day",
            "[bold]yyyy/MM/dd h:mm:ss"
        );
        dateAxis.renderer.inside = true;

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = x.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.tooltipText = "{valueY}";
        x.cursor = new am4charts.XYCursor();

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        x.scrollbarX = scrollbarX;

        chart.current = x;

        return () => {
            x.dispose();
        };
    }, [dataMonitor]);


    return (
        <div>
            {dataMonitor &&
                <>
                    {/* <h1>Monitoreo</h1> */}
                    <div id="chartdiv" style={{ width: "800px", height: "400px" }}></div>

                    <br></br>
                </>
            }
        </div>
    );
};

export default Monitoreo;
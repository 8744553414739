import React from 'react'
import DataGrid, { Column } from 'devextreme-react/data-grid';
import {multiViewItems} from './data2'

const CompanyItem = (props) => {
    // const company = props.data
    // const company = props.data.arraytest
    const company = multiViewItems;
    console.log("DATA TAPS", company)
    const columns = ['Subterraneo', 'Material', 'Númeroductos', 'Diámetroductos', 'longitudlineal', 'Proporcionadoscliente'];
    return (
        <DataGrid
            dataSource={company}
            keyExpr="ID"
            showBorders={true}
        > 
        <Column
          dataField="Subterraneo"
          caption="Subterraneo(ductería) /Externo(ice-bridge)"
        />
        <Column
          dataField="Material"
          caption="Material"
        />
        <Column
          dataField="Númeroductos"
          caption="Número de ductos"
        />
        <Column
          dataField="Diámetroductos"
          caption="Diámetro ductos"
        />
        <Column
          dataField="longitudlineal"
          caption="longitud lineal"
        />
        <Column
          dataField="Proporcionadoscliente"
          caption="Proporcionados por el cliente?"
        />

        </DataGrid>
    )
}

export default CompanyItem;
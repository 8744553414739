import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@material-ui/core";

import NewApp from "./NewApp";

const theme = createTheme({
  palette: {
    primary : {
      main : '#6495ED'
    },
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <NewApp />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

import React, { useState, useEffect } from 'react';

// Styles
import { Wrapper, Content, HeaderItem, RowItem } from './Tabla.styles';
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
} from 'devextreme-react/data-grid';

const Tabla = ({ columns, data }) => {

    const [columnsData, setColumnsData] = useState(columns);
    const [rowData, setRowData] = useState(data);

    useEffect(() => {
        setColumnsData(columns);
        setRowData(data);
    }, [columns, data])

    if (rowData && rowData.length > 0) {
        rowData.map(item => {
            item["mnos_order.created_at"] = item["mnos_order.created_at"].replace("T", " ").replace(".000Z", "");
        })
    }
    let myColumns = []
    let myRows = []
    if (columnsData.length) {
        myColumns = columnsData.map(item => item.title.replace(".", ""))
        myRows = rowData.map(row => {
            let json = {}
            columnsData.map(column =>
                json[column.title.replace(".", "")] = row[column.field]
            )
            json["index"] = row.index
            return json
        })
        console.log({ myColumns })
        console.log({ myRows })
    }

    return (
        <Wrapper>
            {columnsData.length &&
                <Content>

                    <DataGrid
                    style={{backgroundColor:"blue", color:"red"}}
                        dataSource={myRows}
                        defaultColumns={myColumns}
                        showBorders={true}
                    >
                        <Paging defaultPageSize={5} />
                        <Pager
                            visible={true}
                            allowedPageSizes={[5, 10, 20]}
                            // displayMode={this.state.displayMode}
                            showPageSizeSelector={true}
                            // showInfo={this.state.showInfo}
                            // showNavigationButtons={this.state.showNavButtons} 
                        />
                    </DataGrid>
                </Content>
                // <>Hola</>
            }
        </Wrapper>
    );
};

export default Tabla;
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(${({length})=>length}, fit-content(200px));
    margin: 5px 0 10px 0;
    /* grid-gap: 1rem;
    row-gap: 10px; */

`;
import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import { Wrapper, Content, LoadingDiv, Button, LoadingDivExport } from "./ordenes.styles";
import API from "../../API";

// import Tabla from '../Tabla';
import Loader from "../Loader";
import { useUrlFetchPost } from "../../hooks/useUrlFetch";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ShowForPermission from "../../ShowForPermission";
import fileDownload from 'js-file-download'


const Ordenes = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const token = queryParams.get("token");
  const siteId = queryParams.get("siteId");

  console.log("SITEID: ", siteId);
  var apiUrl, sessionVariable, datos, data_send, params;

  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [emptyDataESN, setEmptyDataESN] = useState(false);

  try {
    datos = props.location.state.item;
    apiUrl = datos["v_menus_b360.sourcedata"];
    params = datos["v_menus_b360.params"].replace("requestId", id);
    data_send = {
      id: datos["v_menus_b360.apiConfigId"],
      params: JSON.parse(params),
    };
    console.log("PARAAAAAMETROOOOOO", data_send);
    sessionVariable = window.location.pathname + window.location.search;
  } catch (error) {
    datos = null;
    apiUrl = null;
    sessionVariable = null;
  }
  var apiData = [];
  function startFunctions() {
    setLoading(true);
    var apiData = [];
    var time = document.getElementById("timeSelect").value;
    console.log(time);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data_send),
      redirect: "follow",
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var url_send =
          JSON.parse(result)
          ["response"][0]["value"].split("--")[0]
            .split("?")[0] +
          "?time_span_key=" +
          time.split("-")[0];
        var bearer = JSON.parse(result)
        ["response"][0]["value"].split("--")[2]
          .split("Bearer")[1];
        console.log(
          bearer,
          "bearerbearerbearerbearerbearerbearerbearerbearerbearer"
        );

        var myHeadersx = new Headers();
        myHeadersx.append("Content-Type", "application/json");
        myHeadersx.append("token", token.replace(" ", "").replace("'", ""));

        var requestOptionsx = {
          method: "POST",
          headers: myHeadersx,
          body: JSON.stringify({
            url: url_send,
            token: bearer.replace("'", ""),
          }),
          redirect: "follow",
        };

        fetch(
          "https://onpremise.bifrost.pe/api/status/eutelsatGraphics",
          requestOptionsx
        )
          .then((responsexxx) => responsexxx.text())
          .then((resultxxxx) => {
            setLoading(false);
            console.log(
              JSON.parse(resultxxxx)[0]["attributes"]["series"],
              "asjkldhajsdajsdjkas"
            );
            
            setEmptyData(false);
            setEmptyDataESN(false);
            
            var apiData_x = JSON.parse(resultxxxx)[0]["attributes"]["series"];
            var apiData_y = JSON.parse(resultxxxx)[1]["attributes"]["series"];

            try {
              var tableDataDown = apiData_x[0]["data"];
              var tableDataUp = apiData_x[1]["data"];

              if (tableDataDown.length == 0 && tableDataUp.length == 0) {
                setEmptyData(true);
              }

              var tableDataDownESN = apiData_y[0]["data"];
              var tableDataUpESN = apiData_y[1]["data"];

              if (tableDataDownESN.length == 0 && tableDataUpESN.length == 0) {
                setEmptyDataESN(true);
              }

            } catch (error) {
              var tableDataUp = [];
              var tableDataDown = [{}, {}];
              var tableDataUpESN = [];
              var tableDataDownESN = [{}, {}];

              setEmptyData(true);
              setEmptyDataESN(true);
            }
            am4core.ready(function () {
              am4core.useTheme(am4themes_animated);

              var chart = am4core.create("chartdiv", am4charts.XYChart);
              chart.exporting.menu = new am4core.ExportMenu();
              chart.exporting.menu.align = "left";
              chart.exporting.menu.verticalAlign = "top";

              chart.exporting.menu.items = [
                {
                  label: "...",
                  menu: [
                    { type: "png", label: "PNG" },
                    { type: "json", label: "JSON" },
                    { label: "Print", type: "print" },
                    { label: "Excel", type: "xlsx" },
                  ],
                },
              ];

              chart.padding(0, 0, 0, 0);

              chart.zoomOutButton.disabled = false;
              var data = [];

              var i = 0;
              try {
                for (i = 0; i <= tableDataUp.length; i++) {
                  data.push({
                    date: new Date(tableDataUp[i][0]),
                    upload: tableDataUp[i][1],
                    download: tableDataDown[i][1],
                  });
                }
              } catch (error) { }



              chart.data = data;
              var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
              dateAxis.renderer.grid.template.location = 0;
              dateAxis.dateFormats.setKey("year", "yyyy/MM/dd h:mm:ss");
              dateAxis.periodChangeDateFormats.setKey(
                "year",
                "[bold]yyyy/MM/dd h:mm:ss"
              );
              dateAxis.renderer.inside = false;
              dateAxis.title.text = "Tiempo (" + time.split("-")[0] + ")";

              var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
              valueAxis.renderer.inside = false;
              valueAxis.renderer.minLabelPosition = 0.05;
              valueAxis.renderer.maxLabelPosition = 0.95;
              valueAxis.title.text = "kb/s";

              var range = valueAxis.axisRanges.create();
              range.grid.stroke = am4core.color("#396478");

              var range2 = valueAxis.axisRanges.create();
              range2.grid.stroke = am4core.color("#A96478");
              range2.label.fill = range2.grid.stroke;
              range2.label.verticalCenter = "bottom";

              var series = chart.series.push(new am4charts.LineSeries());
              series.dataFields.dateX = "date";
              series.dataFields.valueY = "download";
              series.showTooltipOn = "always";
              series.name = "Download";
              series.tooltipText = "Download:{valueY}";
              series.tooltip.pointerOrientation = "vertical";
              series.tooltip.background.cornerRadius = 20;
              series.tooltip.background.fillOpacity = 1;
              series.tooltip.label.padding(12, 12, 12, 12);
              series.tooltip.autoTextColor = false;
              series.tooltip.label.fill = am4core.color("#000000");
              // Setting the color of the line
              series.stroke = am4core.color("green");
              series.tooltip.getFillFromObject = false;
              series.tooltip.background.fill = am4core.color("green");


              var seriestwo = chart.series.push(new am4charts.LineSeries());
              seriestwo.dataFields.dateX = "date";
              seriestwo.dataFields.valueY = "upload";
              seriestwo.name = "Upload";
              seriestwo.tooltipText = "Upload:{valueY}";
              seriestwo.tooltip.pointerOrientation = "vertical";
              seriestwo.tooltip.background.cornerRadius = 20;
              seriestwo.tooltip.background.fillOpacity = 1;
              seriestwo.tooltip.label.fill = am4core.color("#000000");
              // Setting the color of the line
              seriestwo.stroke = am4core.color("orange");
              seriestwo.tooltip.getFillFromObject = false;
              seriestwo.tooltip.background.fill = am4core.color("orange");

              chart.legend = new am4charts.Legend();
              chart.legend.position = "top";
              chart.cursor = new am4charts.XYCursor();


              var chart2 = am4core.create("chartdiv2", am4charts.XYChart);
              chart2.exporting.menu = new am4core.ExportMenu();
              chart2.exporting.menu.align = "left";
              chart2.exporting.menu.verticalAlign = "top";

              chart2.exporting.menu.items = [
                {
                  label: "...",
                  menu: [
                    { type: "png", label: "PNG" },
                    { type: "json", label: "JSON" },
                    { label: "Print", type: "print" },
                    { label: "Excel", type: "xlsx" },
                  ],
                },
              ];

              chart2.padding(0, 0, 0, 0);

              chart2.zoomOutButton.disabled = false;
              var data_y = [];

              var i = 0;



              try {
                for (i = 0; i <= tableDataUpESN.length; i++) {
                  data_y.push({
                    date: new Date(tableDataUpESN[i][0]),
                    upload: tableDataUpESN[i][1],
                    download: tableDataDownESN[i][1],
                  });
                }
              } catch (error) { }



              chart2.data = data_y;
              var dateAxis2 = chart2.xAxes.push(new am4charts.DateAxis());
              dateAxis2.renderer.grid.template.location = 0;
              dateAxis2.dateFormats.setKey("year", "yyyy/MM/dd h:mm:ss");
              dateAxis2.periodChangeDateFormats.setKey(
                "year",
                "[bold]yyyy/MM/dd h:mm:ss"
              );
              dateAxis2.renderer.inside = false;
              dateAxis2.title.text = "Tiempo (" + time.split("-")[0] + ")";

              var valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
              valueAxis2.renderer.inside = false;
              valueAxis2.renderer.minLabelPosition = 0.05;
              valueAxis2.renderer.maxLabelPosition = 0.95;
              valueAxis2.title.text = "dB";

              var range = valueAxis2.axisRanges.create();
              range.grid.stroke = am4core.color("#396478");

              var range2 = valueAxis2.axisRanges.create();
              range2.grid.stroke = am4core.color("#A96478");
              range2.label.fill = range2.grid.stroke;
              range2.label.verticalCenter = "bottom";

              var series2 = chart2.series.push(new am4charts.LineSeries());
              series2.dataFields.dateX = "date";
              series2.dataFields.valueY = "download";
              series2.showTooltipOn = "always";
              series2.name = "Download ES/NO";
              series2.tooltipText = "Download ES/NO:{valueY}";
              series2.tooltip.pointerOrientation = "vertical";
              series2.tooltip.background.cornerRadius = 20;
              series2.tooltip.background.fillOpacity = 1;
              series2.tooltip.label.padding(12, 12, 12, 12);
              series2.tooltip.autoTextColor = false;
              series2.tooltip.label.fill = am4core.color("#000000");
              // Setting the color of the line
              series2.stroke = am4core.color("green");
              series2.tooltip.getFillFromObject = false;
              series2.tooltip.background.fill = am4core.color("green");

              var seriestwo2 = chart2.series.push(new am4charts.LineSeries());
              seriestwo2.dataFields.dateX = "date";
              seriestwo2.dataFields.valueY = "upload";
              seriestwo2.name = "Upload ES/NO";
              seriestwo2.tooltipText = "Upload ES/NO:{valueY}";
              seriestwo2.tooltip.pointerOrientation = "vertical";
              seriestwo2.tooltip.background.cornerRadius = 20;
              seriestwo2.tooltip.background.fillOpacity = 1;
              seriestwo2.tooltip.label.fill = am4core.color("#000000");
              // Setting the color of the line
              seriestwo2.stroke = am4core.color("orange");
              seriestwo2.tooltip.getFillFromObject = false;
              seriestwo2.tooltip.background.fill = am4core.color("orange");

              chart2.legend = new am4charts.Legend();
              chart2.legend.position = "top";
              chart2.cursor = new am4charts.XYCursor();

            });
          })
          .catch((errorxxx) => console.log("error", errorxxx));

      });

  }

  useEffect(() => {
    startFunctions();
  }, []);

  const chart = useRef(null);
  const [dataApi, setDataApi] = useState();


  function handleExportar() {
    setLoadingExport(true);
    var dateStart = document.getElementById("startDate").value;
    var date = new Date(dateStart);
    var timestampDateStart = date.getTime();
    var dateEnd = document.getElementById("endDate").value;
    var date = new Date(dateEnd);
    var timestampDateEnd = date.getTime();

    data_send = {
      siteId: 67757,
      start: timestampDateStart,
      end: timestampDateEnd
    };

    apiUrl = "https://pythonsnmp.bifrost.pe/terminal/listTraffic";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data_send),
      redirect: "follow",
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoadingExport(false);
        window.open(result, '_blank');
      })
  }

  return (
    <Wrapper style={{ height: 400 }}>
      <>
        <div>
          <br />
          <br />
          <div >
            <h4>REPORTE GENERAL (EXPORTAR)</h4>
            <label style={{ fontSize: "14px" }}><b>INICIO FECHA:</b> </label>
            <input id="startDate" type="date" />

            <label style={{ marginLeft: "10px", fontSize: "14px" }}><b>FIN FECHA:</b> </label>
            <input id="endDate" type="date" />
            {loadingExport && (
              // <label style={{color: "blue"}}> <b>Cargando ...</b></label>
              <LoadingDivExport />
            )}
            {!loadingExport && (
              <Button onClick={handleExportar} style={{ marginLeft: "10px" }}>Exportar</Button>

            )}
          </div>
          <br />
          <h4>REPORTE PERSONALIZADO</h4>
          <label style={{ fontSize: "14px" }} htmlFor=""> <b>TIEMPO DE INTERVALO:</b> </label>
          <select
            className="select"
            name="timeSelect"
            id="timeSelect"
            onChange={() => startFunctions()}
          >
            <option value="10Minutes-minute-mm" selected>
              10Minutes
            </option>
            <option value="30Minutes-minute-mm">30Minutes</option>
            <option value="12Hours-hour-hh" selected>12Hours</option>
            <option value="2Days-day-dd" >2Days</option>
            <option value="1Week-day-dd">1Week</option>
            <option value="1Month-month-mm">1Month</option>
            <option value="6Months-month-mm">6Months</option>
            <option value="2Years-year-yyyy">2Years</option>
          </select>


        </div>
        <br />
        {loading && (
          <LoadingDiv>
            {/* <h3>Loading</h3> */}
            <Loader />
          </LoadingDiv>
        )}
        {!loading && (
          <ShowForPermission permission="graficoslivesnr">
            {/* {apiData.error && <h1 className="errorHeader">There was a problem with the API, please contact the technical support.</h1>} */}
            <h2 style={{ textAlign: "center" }}>Reporte de Trafico</h2>
            {!emptyData &&
              <div id="chartdiv" style={{ width: "90%", height: "80%", margin: 'auto' }}></div>
            }
            {emptyData && <h3 className="errorHeader">No existen datos para mostrar</h3>}
            <br />
            <br />
            {emptyData && (<><br /><br /><br /></>)}
            <h2 style={{ textAlign: "center" }}>Parametros RF</h2>
            {!emptyDataESN &&
              <div id="chartdiv2" style={{ width: "90%", height: "80%", margin: 'auto' }}></div>
            }
            {emptyDataESN && <h3 className="errorHeader">No existen datos para mostrar</h3>}
            <br />
            <br />
          </ShowForPermission>

        )}
      </>
    </Wrapper>
  );
};

export default Ordenes;

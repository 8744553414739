import styled from "styled-components";

export const Wrapper = styled.div`
  // background: rgba( 204, 225, 235, 0.25 );
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  // backdrop-filter: blur( 2.0px );
  // -webkit-backdrop-filter: blur( 2.0px );
  // border-radius: 10px;
  // border: 1px solid rgba( 255, 255, 255, 0.18 );

  // /* max-width: var(--maxWidth); */
  // margin: 0 auto;
  // padding: 0 20px;
`;

export const Content = styled.div`
    // display: grid;
    // grid-template-columns: repeat(${({ length }) =>length}, fit-content(200px));
    // margin: 5px 0 10px 0;
    // column-count: 2;     
    // column-fill: 13;
    display: flex;
    flex-direction: column;
    
    

`;

export const ContentOut = styled.div`
    // display: grid;
    // grid-template-columns: repeat(${({ length }) =>length}, fit-content(200px));
    // margin: 5px 0 10px 0;
    // column-count: 2;     
    // column-fill: 13;
    // flex-direction: column;
    display: flex;
    .column:last-child{
      background-color: #555;
      border-radius: 10px;
    }
    

`;

export const HeaderItem = styled.div`
  padding: 20px 15px;
  margin: 5px 2px 5px 2px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(6, 163, 243, 0.3);
  border-radius: 10px;
  color: var(--white);
  
`;

export const RowItem = styled.div`
  text-align: center;
  padding: 20px 15px;
  margin: 5px 2px 5px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-bottom: 1px solid white;
  color: var(--white);
  display: block;
  text-align: center;
  max-width: 150px;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  

  ${({ info }) => (info === "Procesada" ? "color: green;" : "")}
  ${({ styles }) => styles}
`;

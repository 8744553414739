import styled from 'styled-components';

export const Wrapper = styled.div`
    /* width: 80%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 600px; */
`;

export const LoadingDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 20px;
    justify-self: center;
    align-self: center;
    h3{
        color: white;
    }
`;
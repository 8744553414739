import React from 'react'
import DataGrid, { FilterRow, HeaderFilter, Pager, Paging, SearchPanel, Column } from 'devextreme-react/data-grid';
import { customers } from './data';

const TP = () => {
    // const columns = ['CompanyName', 'City', 'State', 'Phone', 'Fax'];

    const pageSizes = [5, 10, 20]
    return (
        <div align="center">
            <h1>Trabajos Programados</h1>
            <DataGrid
                dataSource={customers}
                keyExpr="ID"
                // defaultColumns={columns}
                showBorders={true}
                width={'90%'}
            >
                <Column
                    dataField="ID"
                    caption="ID"
                />
                <Column
                    dataField="NombreContrato"
                    caption="Nombre Contrato"
                />
                <Column
                    dataField="Descripcion"
                    caption="Descripcion del Trabajo"
                />
                <Column
                    dataField="FechaInicio"
                    caption="Fecha inicio Porgramada"
                />
                <Column
                    dataField="FechaFin"
                    caption="Fecha Fin Programada"
                />
                <Column
                    dataField="Duracion"
                    caption="Duracion"
                />
                <Column
                    dataField="Estado"
                    caption="Estado"
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                <Paging defaultPageSize={10} />
                <SearchPanel visible={true} highlightCaseSensitive={true} width={400} />
            </DataGrid>
        </div>
    )
}

export default TP;

import React from "react";
import {
    Wrapper,
    Wrapper2,
    Wrapper3,
    Button,
    LoadingDiv,
    HeaderItem,
} from "./service.styles";
import DataGrid,
{
    Column,
    MasterDetail,
} from 'devextreme-react/data-grid';
import Detail from './DetailTaps';
import service from './data';

const employees = service.getEmployees();

const Servicio = () => {


    return (
        <>
            <Wrapper >
                <h1>Servicio</h1>
                <DataGrid id="grid-container"
                    dataSource={employees}
                    keyExpr="ID"
                    showBorders={true}
                >
                    <Column dataField="Prefix" width={70} caption="Nombre de Servicio" />
                    <MasterDetail
                        enabled={true}
                        component={Detail}
                    />
                </DataGrid>
            </Wrapper>
        </>
    );
};

export default Servicio;

import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Wrapper, Content} from './Button.styles'
import swal from 'sweetalert';
import ProgressBar from '../ProgressBar';
import Loader from '../Loader';
import {useUrlFetch, useUrlFetchPost} from '../../hooks/useUrlFetch';
import {TableContent} from '../Actions/Actions.styles'

const Button = ({data, listHandler}) => {
    return (
        <Wrapper>
            <Content onClick={()=>handler(data, listHandler)} styles={data.style}>
                {data.title}
            </Content>
        </Wrapper>
    );
};

const handler = (data, listHandler) => {
    if(data.type === "alert"){
        listHandler(null);
        swal({
            title: data.title,
            text: data.warning_message,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((ok) => {
            if(ok){
                const ApiHandler = () => {
                    const apiData = useUrlFetchPost(data.api_handler,data.params, "dont save");
                    useEffect(() => {
                        if(apiData.state){
                            swal({
                                title: data.success_message,
                                icon: "success",
                            });
                        }
                        if(apiData.error){
                            swal({
                                title: data.error_message,
                                icon: "error",
                            });
                        }
                    },[apiData])
                    return <></>;
                }
                let wrapper1 = document.createElement('div');
                ReactDOM.render(<ApiHandler />, wrapper1);

                let wrapper = document.createElement('div');
                ReactDOM.render(<ProgressBar />, wrapper);
                let el = wrapper.firstChild;
                swal({
                    title: data.title,
                    text: data.description,
                    content: el
                });
            }
        })
    }else if(data.type === "list"){
        const ApiHandler = () => {
            const sessionVariable = window.location.pathname+window.location.search+JSON.stringify(data.params);
            // const apiData = useUrlFetch("https://mocki.io/v1/02a1f8d0-fccc-4d9d-8d98-e26cc20e2a42");
            const apiDataPost = useUrlFetchPost(data.api_handler,data.params, sessionVariable);
            
            useEffect(() => {
                if(apiDataPost.loading){
                    listHandler(<>
                        <h3>{data.warning_message}</h3>
                        <Loader/>
                    </>);
                }
                if(!apiDataPost.loading){
                    // this is a line that we can improve
                    listHandler(
                        <TableContent>
                            <div className="injectedHTML" dangerouslySetInnerHTML={{__html: JSON.parse(apiDataPost.state[0]).value}}></div>
                        </TableContent>
                    );
                }
            },[apiDataPost])

            return <>
            </>;
        }
        let wrapper = document.createElement('div');
        ReactDOM.render(<ApiHandler />, wrapper);

    }
}
export default Button
import React, {useState, useEffect} from "react";

// Components
import Loader from '../Loader';
import * as config from '../../config';
// import {APICONFIGLVL2} from '../../config';
// import {APICONFIGLVL3} from '../../config';

// Hooks
import {useUrlFetchPost,useUrlFetchPostOrdered} from '../../hooks/useUrlFetch';
import { BrowserRouter as Router, Redirect } from "react-router-dom";

import React360Logo from '../../assets/images/React360Logo.png';
import { Wrapper, Content, ImgContent } from './SplashScreen.styles';
import {setGlobalState} from '../../session';


const SplashScreen = ({callback}) => {

    // Fetching from the APIs
    
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    const categoryId = queryParams.get('categoryId');
    const siteId = queryParams.get('siteId');
    console.log("entrada")

    const level1 = useUrlFetchPost(config.URL_LEVEL1,{"id":config['APICONFIGLVL1_'+String(categoryId)],"params":{"requestBtId":id}}, "LEVEL1/"+id+categoryId);
    const level2 = useUrlFetchPost(config.URL_LEVEL2,{"id":config['APICONFIGLVL2_'+String(categoryId)],"params":{"requestBtId":id}}, "LEVEL2/"+id+categoryId);
    const level3 = useUrlFetchPostOrdered(config.URL_LEVEL3,{"id":config['APICONFIGLVL3_'+String(categoryId)],"params":{"requestCategoryId":String(categoryId)}},"v_menus_b360.order", 1, "LEVEL3/"+id+categoryId);

    const [firstPage, setFirstPage] = useState(null);
    level3.state = level3.state[0]
    useEffect(()=>{
        if(!level3.loading && !level2.loading && !level1.loading){
            setGlobalState('hasLoadedForFirstTime/'+id+categoryId,level3.state[0]["v_menus_b360.href"]);
            setFirstPage(level3.state[0]["v_menus_b360.href"]);
            if(firstPage)
                setTimeout(() => {
                    callback();
                },5000);
        }
    },[level1, level2, level3, callback, firstPage]);

    var item;
    try{
        item = level3.state[0];
    } catch(error){
        item = null
    }
    
    return (
            <Wrapper>
                <Content>
                    <ImgContent>
                        <img src={React360Logo} alt="logo"/>
                    </ImgContent>
                  
                    <h1>Cargando</h1>
                    <Loader color="background: white;"/>
                </Content>
                {firstPage && item &&
                <Router>
                    {console.log(firstPage)}
                    <Redirect to={{      
                      pathname: item["v_menus_b360.href"]+window.location.search, 
                      state: {item}
                    }}/>
                </Router>
                }
            </Wrapper>
    )
};

export default SplashScreen;

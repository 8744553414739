import styled from 'styled-components';

export const TestW = styled.div`
    width: 100%;
    padding: 150px;
  
    display: flex;
    justify-content: center;
    overflow: hidden;
    
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100px;
    min-width: 100px;
    height: 100px;
    padding: 0;
    margin: 0;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: white;
    cursor: pointer;
    margin: auto;
    color: #2d68c4;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    transition: 0.24s 0.2s;

    :hover .menu{
        transform: scale(1);
        z-index: 40;
    }

:hover .spread, .icon:hover .unit {
  transition: all 0.6s;
}
:hover .spread:nth-child(1) {
  transition-delay: 0.02s;
  /* transform: rotate(70deg); */
  transform: rotate(0deg);
  /* transform: translate(0px,220px); */
  
}
:hover .spread:nth-child(1) .unit {
  transition-delay: 0.04s;
  /* transform: rotate(650deg); */
  transform: rotate(0deg);

}
:hover .spread:nth-child(2) {
  transition-delay: 0.04s;
  transform: rotate(36deg);
  /* transform: translate(0px,292px); */
}
:hover .spread:nth-child(2) .unit {
  transition-delay: 0.08s;
  /* transform: rotate(610deg); */
  transform: rotate(-36deg);

}
:hover .spread:nth-child(3) {
  transition-delay: 0.06s;
  transform: rotate(72deg);
  /* transform: translate(0px,364px); */
}
:hover .spread:nth-child(3) .unit {
  transition-delay: 0.12s;
  /* transform: rotate(570deg); */
  transform: rotate(-72deg);
}
:hover .spread:nth-child(4) {
  transition-delay: 0.08s;
  transform: rotate(108deg);
  /* transform: translate(0px,436px); */
}
:hover .spread:nth-child(4) .unit {
  transition-delay: 0.16s;
  transform: rotate(-108deg);
  /* transform: rotate(530deg); */
}
:hover .spread:nth-child(5) {
  transition-delay: 0.1s;
  transform: rotate(144deg);
  /* transform: translate(0px,508px); */

}
:hover .spread:nth-child(5) .unit {
  transition-delay: 0.2s;
  transform: rotate(-144deg);
  /* transform: rotate(475deg); */
}
:hover .spread:nth-child(6) {
  transition-delay: 0.12s;
  transform: rotate(180deg);
  /* transform: translate(0px,580px); */

}
:hover .spread:nth-child(6) .unit {
  transition-delay: 0.24s;
  transform: rotate(-180deg);
  /* transform: rotate(435deg); */
}
:hover .spread:nth-child(7) {
  transition-delay: 0.14s;
  transform: rotate(216deg);
  /* transform: translate(0px,652px); */
}
:hover .spread:nth-child(7) .unit {
  transition-delay: 0.28s;
  transform: rotate(-216deg);
  /* transform: rotate(395deg); */
}
:hover .spread:nth-child(8) {
  transition-delay: 0.16s;
  transform: rotate(252deg);
  /* transform: translate(0px,724px); */
}
:hover .spread:nth-child(8) .unit {
  transition-delay: 0.32s;
  transform: rotate(-252deg);
  /* transform: rotate(355deg); */
}

:hover .spread:nth-child(9) {
  transition-delay: 0.16s;
  transform: rotate(288deg);
  /* transform: translate(0px,724px); */
}
:hover .spread:nth-child(9) .unit {
  transition-delay: 0.32s;
  transform: rotate(-288deg);
  /* transform: rotate(355deg); */
}

:hover .spread:nth-child(10) {
  transition-delay: 0.16s;
  transform: rotate(324deg);
  /* transform: translate(0px,724px); */
}
:hover .spread:nth-child(10) .unit {
  transition-delay: 0.32s;
  transform: rotate(-324deg);
  /* transform: rotate(355deg); */
}

//Mostrar siempre
  .menu{
        transform: scale(1);
        z-index: 40;
    }
    .unit{
      height: 80px;
      width: 80px;
      margin: 25px
    }

 .spread, .icon:hover .unit {
  transition: all 0.6s;
  
}
 .spread:nth-child(1) {
  transition-delay: 0.02s;
  /* transform: rotate(70deg); */
  transform: rotate(0deg);
  /* transform: translate(0px,220px); */
  
  
}
 .spread:nth-child(1) .unit {
  transition-delay: 0.04s;
  /* transform: rotate(650deg); */
  transform: rotate(0deg);
  
}
 .spread:nth-child(2) {
  transition-delay: 0.04s;
  transform: rotate(36deg);
  /* transform: translate(0px,292px); */
}
 .spread:nth-child(2) .unit {
  transition-delay: 0.08s;
  /* transform: rotate(610deg); */
  transform: rotate(-36deg);

}
 .spread:nth-child(3) {
  transition-delay: 0.06s;
  transform: rotate(72deg);
  /* transform: translate(0px,364px); */
}
 .spread:nth-child(3) .unit {
  transition-delay: 0.12s;
  /* transform: rotate(570deg); */
  transform: rotate(-72deg);
}
 .spread:nth-child(4) {
  transition-delay: 0.08s;
  transform: rotate(108deg);
  /* transform: translate(0px,436px); */
}
 .spread:nth-child(4) .unit {
  transition-delay: 0.16s;
  transform: rotate(-108deg);
  /* transform: rotate(530deg); */
}
 .spread:nth-child(5) {
  transition-delay: 0.1s;
  transform: rotate(144deg);
  /* transform: translate(0px,508px); */

}
 .spread:nth-child(5) .unit {
  transition-delay: 0.2s;
  transform: rotate(-144deg);
  /* transform: rotate(475deg); */
}
 .spread:nth-child(6) {
  transition-delay: 0.12s;
  transform: rotate(180deg);
  /* transform: translate(0px,580px); */

}
 .spread:nth-child(6) .unit {
  transition-delay: 0.24s;
  transform: rotate(-180deg);
  /* transform: rotate(435deg); */
}
 .spread:nth-child(7) {
  transition-delay: 0.14s;
  transform: rotate(216deg);
  /* transform: translate(0px,652px); */
}
 .spread:nth-child(7) .unit {
  transition-delay: 0.28s;
  transform: rotate(-216deg);
  /* transform: rotate(395deg); */
}
 .spread:nth-child(8) {
  transition-delay: 0.16s;
  transform: rotate(252deg);
  /* transform: translate(0px,724px); */
}
 .spread:nth-child(8) .unit {
  transition-delay: 0.32s;
  transform: rotate(-252deg);
  /* transform: rotate(355deg); */
}
.spread:nth-child(9) {
  transition-delay: 0.16s;
  transform: rotate(288deg);
  /* transform: translate(0px,724px); */
}
 .spread:nth-child(9) .unit {
  transition-delay: 0.32s;
  transform: rotate(-288deg);
  /* transform: rotate(355deg); */
}
.spread:nth-child(10) {
  transition-delay: 0.16s;
  transform: rotate(324deg);
  /* transform: translate(0px,724px); */
}
 .spread:nth-child(10) .unit {
  transition-delay: 0.32s;
  transform: rotate(-324deg);
  /* transform: rotate(355deg); */
}
`;

export const Menu = styled.ul`
    position: absolute;
    top: 0;
    left: 0;
    border: 145px solid transparent;
    cursor: default;
    border-radius: 40%;
    transform: scale(0);
    transition: transform 1.4s 0.07s;
    

    ${Content}:hover &{
        transform: scale(1);
        z-index: 5;
        
    }
`;

export const Item = styled.li`
    position: absolute;
    height: 71px;
    width: 71px;
    top: -150px;
    left: -50px;
    /* transform-origin: 70px 100px; */
    transition: all 0.5s 0.1s;
    z-index: 40;
  

    ${Content}:hover ${Content}:nth-child(${child=>`{child}`}) & {
        transition-delay: 0.02s;
        /* transform: rotate(70deg); */
        transform: rotate(90deg);
        transform: ${ ({child}) => `translate(0px,${220+child*100}px)`};
      }
`;

export const ItemLink = styled.a``;


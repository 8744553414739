// import { Container, Grid } from "@material-ui/core";
import React, { useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { useTableFetch } from "../../hooks/useTableFetch";
import { Wrapper, LoadingDiv } from './Mapa.styles'
import Loader from "../Loader";
import { GOOGLE_API_KEY } from "../../config";
import { useUrlFetchPost } from '../../hooks/useUrlFetch';
import ShowForPermission from '../../ShowForPermission'


const Mapa = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  var apiUrl, sessionVariable, datos, data_send, params;
  try {
    datos = props.location.state.item;
    apiUrl = datos["v_menus_b360.sourcedata"];
    params = datos["v_menus_b360.params"].replace("requestId", id);
    console.log(apiUrl);
    data_send = {
      id: datos["v_menus_b360.apiConfigId"],
      params: JSON.parse(params),
    };
  
    sessionVariable = window.location.pathname + window.location.search;
  } catch (error) {
    datos = null;
    apiUrl = null;
    sessionVariable = null;
  }
  
  // console.log(GOOGLE_API_KEY)
    // reading from the api
    const apiData = useUrlFetchPost(apiUrl, data_send, sessionVariable);
    // data to show
    const data = apiData.state;
    console.log(data);
  const [selected, setSelected] = useState(null);

  var MapWithAMarker;



  MapWithAMarker = withScriptjs(
    withGoogleMap(() => (
      
      <GoogleMap
        defaultZoom={data[0].zoom}
        defaultCenter={
          {
            lat: Number(data[0].center[0]['bifrost_terminal.latitud']),
            lng: Number(data[0].center[0]['bifrost_terminal.longitud'])
          }
        }
      >
        <Marker
          position={
            {
              lat: Number(data[0].markers[0]['bifrost_terminal.latitud']),
            lng: Number(data[0].markers[0]['bifrost_terminal.longitud'])
            }
          }
          onClick={() => setSelected(true)}
        // onMouseOver={() => setSelected(true)}-10.749446015195637, -77.76547889484036
        // onMouseDown={() => setSelected(false)}

        >
          
        </Marker>
      </GoogleMap>
    ))
  );


  return (
    <Wrapper>
            
          {!data && <h2 className="errorHeader">NO DATA</h2>}
            {apiData.loading && (
              <LoadingDiv>
                <h3>Loading</h3>
                <Loader />
              </LoadingDiv>
            )}
            {datos && data.length &&(

          <ShowForPermission permission="mapa">

            <MapWithAMarker
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `80%` }} />}
              containerElement={<div style={{ height: `450px`, padding: `50px`, width: '80%' }} />}
              mapElement={<div style={{ height: `80%` }} />}
            />
          </ShowForPermission>
     
        )}
    </Wrapper>
  );
};

export default Mapa;

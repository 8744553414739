import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: auto;
`;

export const Content = styled.button`
    background: #007bff;
    color: white;
    height: auto;
    min-height: 50px;
    align-self: center;
    width: fit-content;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;

    ${({styles})=> styles}
`;